import { PropsWithChildren, useEffect } from 'react';
import {
  Button,
  Flex,
  Stack,
  TextInput,
  Title,
  MultiSelect,
  Dialog,
} from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Commodity } from '../../schema/supplier';
import { Commodities, Plot, UpdatePlot, Geometry } from '../../shared/types';
import { UpdatePlotSchema } from '../../schema/update-plot';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTableState, useTracking } from '../../hooks';
import { UseMutationResult } from '@tanstack/react-query';
import { SELECTED_PLOT_KEY } from '../../common/constants';

type UpdateFormProps = {
  draw: any;
  onDeletePlot: () => void;
  features: Geometry | any;
  plot: Plot | undefined;
  updatePlot: UseMutationResult<any, unknown, UpdatePlot, unknown>;
};

export const UpdatePlotForm = ({
  draw,
  onDeletePlot,
  features,
  plot,
  updatePlot,
}: UpdateFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { setTableState } = useTableState();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdatePlot>({
    defaultValues: {
      name: plot?.name,
      commodities: plot?.commodities as Commodity[],
      isActive: true,
    },
    resolver: yupResolver(UpdatePlotSchema),
  });

  const [searchParams] = useSearchParams();
  const hasSelectedPlot = searchParams.get(SELECTED_PLOT_KEY);

  /*
    This is needed to ensure the chosen plot to edit is added to the draw state
    on browser reload and when the edit button is selected from the side panel 
    in the plot-details map state.
  */
  if (plot && !draw.getAll()?.features.length) {
    const plotToEdit = {
      type: 'Feature',
      properties: {},
      geometry: plot.geometry,
    };
    draw.add(plotToEdit);
  }

  const translatedCommodities = Object.entries(Commodities).map(
    ([label, value]) => ({
      value,
      label: t<string>(`common.commodities.${label}`),
    })
  );

  useEffect(() => {
    if (hasSelectedPlot) {
      handleUpdateReset();
    }
  }, [hasSelectedPlot]);

  const handleUpdateReset = () => {
    searchParams.set('plots', '1');
    navigate(
      {
        pathname: '/map/plots',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );

    if (!hasSelectedPlot) {
      onDeletePlot();
      setTableState({ [SELECTED_PLOT_KEY]: plot?.id });
    }
  };

  const onSubmit = async (data: UpdatePlot) => {
    updatePlot.mutate(
      {
        ...data,
        ...(!isEmpty(features) && { geometry: features.geometry }),
      },
      {
        onSuccess: () => {
          trackEvent('Map', {
            step: 'edit-plot',
            action: 'submit',
          });
          handleUpdateReset();
        },
      }
    );
  };

  return (
    <Dialog
      opened={!!features}
      onClose={handleUpdateReset}
      position={{ top: 200, right: 20 }}
      withCloseButton
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack justify="space-between">
          <Title order={3} data-testid="edit-plot-title">
            {t<string>('Edit plot details')}{' '}
          </Title>
          <Stack gap="lg">
            <TextInput
              {...register('name')}
              withAsterisk
              label={t<string>('map.sidePanel.details.fields.name')}
              error={errors?.name?.message}
              data-testid="plot-name"
            />
            <Controller
              name="commodities"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  searchable
                  withAsterisk
                  label={t<string>('map.sidePanel.details.fields.commodities')}
                  error={errors?.commodities?.message}
                  data={translatedCommodities}
                  data-testid="crop-grown"
                />
              )}
            />
          </Stack>
          {/* TODO confirm if surface area needed as an editable field */}
          <Flex<PropsWithChildren<any>> justify="space-evenly">
            <Button
              variant="outline"
              onClick={() => {
                trackEvent('Map', {
                  step: 'edit-plot',
                  action: 'cancel',
                });
                handleUpdateReset();
              }}
              disabled={updatePlot.isLoading}
            >
              {t<string>('common.cancel')}
            </Button>
            <Button type="submit">{t<string>('common.save')}</Button>
          </Flex>
        </Stack>
      </form>
    </Dialog>
  );
};
