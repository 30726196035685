import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useTranslation } from 'react-i18next';
import { useFetchApi } from './useFetchApi';
import { User } from '../shared/types';

type InvitationSent = {
  id: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  status: 'PENDING' | 'ACCEPTED' | 'REJECTED' | 'EXPIRED';
  sentByUser?: User;
};

type BusinessMember = {
  id: string;
  createdAt: string;
  updatedAt: string;
  completed: boolean;
  email: string;
  firstName: string;
  lastName: string;
  consentedAt: string;
};

export type Invitation = {
  id: string;
  email: string;
  name: string;
  status: 'PENDING' | 'ACCEPTED' | 'REJECTED' | 'EXPIRED';
};

export type EmailInvitation = {
  email: string;
};

export type SendInvitationError = {
  message: string;
  statusCode: number;
};

export const isApiError = (error: unknown): error is SendInvitationError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'statusCode' in error &&
    'message' in error
  );
};

const useInvitations = () => {
  const queryClient = useQueryClient();
  const fetch = useFetchApi();
  const { successfullyUpdated, apiError } = useNotification();
  const { t } = useTranslation();

  const invitations = useQuery<Invitation[]>({
    queryKey: ['invitations'],
    queryFn: async (): Promise<Invitation[]> => {
      const invitationsSent: InvitationSent[] = await fetch(
        'user-invitations/sent'
      );
      const businessMembers: BusinessMember[] = await fetch(
        'businesses/me/members'
      );

      const legacyInvitations =
        businessMembers
          ?.filter(
            ({ email }) =>
              email &&
              !invitationsSent?.some((invitation) => invitation.email === email)
          )
          ?.map(({ email, firstName, lastName }) => ({
            id: email,
            email,
            name: `${firstName || ''} ${lastName || ''}`.trim(),
            status: 'ACCEPTED' as const,
          })) ?? [];

      const invitations = invitationsSent?.map(({ id, email, status }) => {
        const member = businessMembers?.find(
          (member) => member.email === email
        );

        const name = `${member?.firstName || ''} ${
          member?.lastName || ''
        }`.trim();

        return {
          id,
          email,
          name: name.length > 0 ? name : '---',
          status,
        };
      });

      return [...legacyInvitations, ...invitations];
    },

    onError: apiError,
  });

  const sendInvitation = useMutation<
    Invitation,
    SendInvitationError,
    EmailInvitation
  >({
    mutationFn: async (invitation: EmailInvitation) =>
      fetch('user-invitations', {
        method: 'POST',
        body: JSON.stringify(invitation),
      }),
    onSuccess: ({ email, status, id }) => {
      const newInvitation = {
        id,
        name: '---',
        email,
        status,
      };

      queryClient.setQueryData(
        ['invitations'],
        (invitations: Invitation[] = []) => [...invitations, newInvitation]
      );

      successfullyUpdated(t('invitations.title'));
    },
    onError: apiError,
  });

  return {
    invitations,
    sendInvitation,
  };
};

export default useInvitations;
