import {
  Modal,
  Stack,
  Text,
  Button,
  Group,
  Checkbox,
} from '@liveeo/component-library';
import { useToggle } from '@liveeo/helpers';
import { useTranslation } from 'react-i18next';

type SubmissionConfirmationModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void> | void;
};

export const SubmissionConfirmationModal = ({
  isOpen,
  onCancel,
  onConfirm,
  isLoading,
}: SubmissionConfirmationModalProps) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useToggle();
  const handleCancel = () => {
    onCancel();
    setChecked(false);
  };
  return (
    <Modal
      closeButtonProps={{ 'aria-label': t<string>('common.cancel') }}
      title={t('documents.submit.title')}
      opened={isOpen}
      onClose={handleCancel}
      centered
    >
      <Stack mb="sm">
        <Text>{t('documents.submit.body')}</Text>
        <Checkbox
          label={t('documents.submit.checkbox')}
          onChange={(event) => setChecked(event.currentTarget.checked)}
          checked={checked}
        />

        <Group justify="end">
          <Button variant="outline" onClick={handleCancel} loading={isLoading}>
            {t<string>('common.cancel')}
          </Button>

          <Button
            variant="outline"
            onClick={async () => {
              await onConfirm();
              setChecked(false);
            }}
            disabled={!checked}
            loading={isLoading}
          >
            {t('documents.submit.confirm')}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
