import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';

export type AssessmentStatus =
  | 'NEW'
  | 'UNDER_REVIEW'
  | 'READY_FOR_ASSESSMENT'
  | 'CMS_ASSESSMENT_REQUESTED'
  | 'CMS_ASSESSMENT_COMPLETED'
  | 'IN_RISK_MITIGATION';

export type SupplierRiskAssessments = {
  id: string;
  supplierId: string;
  status: AssessmentStatus;
  notes: string;
};

type SupplierRiskAssessmentMutation = UseMutationResult<
  SupplierRiskAssessments[],
  unknown,
  SupplierRiskAssessments,
  unknown
>;

type UseSupplierRiskAssessmentsResult = {
  data: SupplierRiskAssessments[] | undefined;
  isLoading: boolean;
  isFetching: boolean;
  createAssessment: SupplierRiskAssessmentMutation;
  updateAssessment: SupplierRiskAssessmentMutation;
};

export const RISK_ASSESSMENTS_QUERY = 'supplier-risk-assessments';

export const useGetSupplierRiskAssessments = () => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  return useQuery<SupplierRiskAssessments[]>({
    queryKey: [RISK_ASSESSMENTS_QUERY],
    queryFn: () => fetch('supplier-risk-assessments/given'),
    onError: apiError,
    refetchOnWindowFocus: false,
  });
};

export const useSupplierRiskAssessments =
  (): UseSupplierRiskAssessmentsResult => {
    const fetch = useFetchApi();
    const { showErrorWithResponse, successfullyUpdated } = useNotification();
    const queryClient = useQueryClient();

    const { data, isLoading, isFetching } = useGetSupplierRiskAssessments();

    const onSuccess = () => {
      queryClient.invalidateQueries({
        queryKey: [RISK_ASSESSMENTS_QUERY],
      });
      successfullyUpdated('Supplier status');
    };

    const createAssessment = useMutation({
      mutationFn: (data: SupplierRiskAssessments) => {
        const url = `businesses/${data.supplierId}/supplier-risk-assessments`;
        return fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            status: data.status,
            notes: data.notes,
          }),
        });
      },
      onSuccess,
      onError: showErrorWithResponse,
    });

    const updateAssessment = useMutation({
      mutationFn: (data: SupplierRiskAssessments) => {
        const url = `supplier-risk-assessments/${data.id}`;
        return fetch(url, {
          method: 'PATCH',
          body: JSON.stringify({
            status: data.status,
            notes: data.notes,
          }),
        });
      },
      onSuccess,
      onError: showErrorWithResponse,
    });

    return {
      data,
      isLoading,
      isFetching,
      createAssessment,
      updateAssessment,
    };
  };
