import { useTranslation } from 'react-i18next';
import useInvitations, { Invitation } from '../../../hooks/useInvitations';
import NewTable from '../../../shared/components/NewTable/NewTable';
import { InviteMembers } from './InviteMembersModal';
import { StringParam, useQueryParam } from 'use-query-params';
import { Box } from '@mantine/core';
import classes from '../Admin.module.css';

export const InvitationsTable = () => {
  const { t } = useTranslation();
  const [, setInvite] = useQueryParam('invite', StringParam);
  const { invitations } = useInvitations();

  const columns = [
    { value: 'name', label: t('invitations.name'), sort: true },
    { value: 'email', label: t('invitations.email'), sort: true },
    {
      value: 'status',
      label: t('invitations.status'),
      sort: true,
      translationPath: 'invitations.statuses',
    },
  ];

  return (
    <Box className={classes.invitationsTable}>
      <NewTable<Invitation>
        header={{
          title: t<string>('invitations.title'),
          onAdd: () => setInvite('email'),
        }}
        data={invitations?.data}
        columns={columns}
        loading={invitations?.isLoading}
        empty={{
          entity: t('invitations.title').toLowerCase(),
          onClick: () => setInvite('email'),
          icon: 'user-plus',
        }}
        style={{
          height: 'calc(100dvh - 120px)',
          width: '100%',
          position: 'static',
          border: 'none',
        }}
        virtualized
      />
      <InviteMembers />
    </Box>
  );
};
