import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import ActionsMenu from '../../../shared/components/NewTable/ActionMenu';
import { SELECTED_PLOT_KEY } from '../../../common/constants';

const AddPlots = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [, setImportModal] = useQueryParam('import-modal');
  const { t } = useTranslation();
  const handleImportPlots = () => {
    setImportModal('open');
  };

  const handleDrawPlot = () => {
    searchParams.delete('expanded');
    searchParams.delete(SELECTED_PLOT_KEY);

    notifications.show({
      id: 'draw-plot',
      title: t('map.plots.drawPlot'),
      message: t('map.plots.drawHint'),
      autoClose: false,
    });
    navigate(
      {
        pathname: `/map/plots/draw-plot`,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  };

  const handleCancelDrawPlot = () => {
    notifications.hide('draw-plot');
    navigate({ pathname: '/map/plots', search: location.search });
  };

  const showCancelDrawPlot = location.pathname.includes('draw-plot');

  return (
    <ActionsMenu
      icon="plus"
      offset={10}
      position="right-start"
      buttonStyle={{ padding: 0 }}
      singleAction={
        showCancelDrawPlot
          ? {
              tooltip: t<string>('map.plots.cancelDraw'),
              icon: 'close',
              onClick: handleCancelDrawPlot,
            }
          : undefined
      }
      list={[
        {
          label: t<string>('map.plots.importPlots'),
          onClick: handleImportPlots,
        },
        { label: t<string>('map.plots.drawPlot'), onClick: handleDrawPlot },
      ]}
    />
  );
};

export default AddPlots;
