import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpFromBracket,
  faBell,
  faCalendar,
  faCalendarCheck,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCirclePlus,
  faClipboard,
  faCloudArrowUp,
  faDrawPolygon,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFilter,
  faGear,
  faGlobe,
  faImage,
  faInfoCircle,
  faLayerGroup,
  faListCheck,
  faLocationPin,
  faLock,
  faMagnifyingGlass,
  faMinus,
  faPaperPlane,
  faPencil,
  faPenToSquare,
  faPlus,
  faSliders,
  faSort,
  faSortDown,
  faSortUp,
  faStop,
  faTrash,
  faTree,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

import { faCalendarCheck as faCalendarCheckRegular } from '@fortawesome/free-regular-svg-icons';

export const availableIcons = [
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpFromBracket,
  faBell,
  faCalendar,
  faCalendarCheck,
  faCalendarCheckRegular,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCirclePlus,
  faClipboard,
  faCloudArrowUp,
  faDrawPolygon,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFilter,
  faGear,
  faGlobe,
  faImage,
  faInfoCircle,
  faLayerGroup,
  faListCheck,
  faLocationPin,
  faLock,
  faMagnifyingGlass,
  faMinus,
  faPaperPlane,
  faPencil,
  faPenToSquare,
  faPlus,
  faSliders,
  faSort,
  faSortDown,
  faSortUp,
  faStop,
  faTrash,
  faTree,
  faUser,
  faXmark,
];

library.add(...availableIcons);
