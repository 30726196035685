import {
  Beta,
  DataGridVirtualized,
  Box,
  DataGrid,
} from '@liveeo/component-library';
import { FiltersProps } from '../../../shared/types';
import useColumns, { CellProps, Column } from './useColumns';
import useSort from './useSort';
import { GenericErrorBoundary } from '../GenericErrorBoundary';
import CardList from './CardList';
import { EmptyTable, EmptyTableProps } from './EmptyTable';
import { Center, Loader, Text } from '@mantine/core';
import { formatDate } from '../../../helper';
import Search, { SearchProps, useSearch } from './Search';
import { Filters, useFilters } from './Filters';
import clsx from 'clsx';
import SidePanel, { SidePanelProps } from './SidePanel';
import { useSearchParams } from 'react-router-dom';
import Header, { HeaderProps } from './Header';
import { SelectedKey } from '../../../common/constants';
import { useEffect, useRef } from 'react';
import { TableVirtuosoHandle } from 'react-virtuoso';
import classes from './NewTable.module.css';
import useScrollToSelection from './useScrollToSelection';

type NewTableProps<T extends { id: string }> = {
  aside?: React.ReactNode;
  cardRows?: string[][];
  columns: Column<T>[];
  data: T[] | undefined;
  empty?: EmptyTableProps;
  filters?: FiltersProps;
  header?: HeaderProps;
  height?: string | number | undefined;
  loading?: boolean;
  onSelect?: (card: T) => void;
  search?: SearchProps & { keys: string[] };
  selectKey?: SelectedKey;
  sidePanel?: Omit<SidePanelProps<T>, 'data'>;
  style?: React.CSSProperties;
  virtualized?: boolean;
  width?: string | number | undefined;
  renderCardBorderRight?: (card: T) => string | undefined;
  hasNextPage?: boolean;
};

const NewTable = <T extends { id: string }>({
  aside,
  cardRows,
  columns,
  data = [],
  empty,
  filters,
  header,
  loading,
  onSelect,
  search,
  selectKey,
  sidePanel,
  style,
  virtualized,
  renderCardBorderRight,
}: NewTableProps<T>) => {
  const { sort, sortedData, handleSort } = useSort(data);
  const [searchParams, setSearchParams] = useSearchParams();

  const expanded = searchParams.get('expanded');

  const searchedData = useSearch<T>({
    data: sortedData,
    keys: search?.keys,
    searchKey: 'search',
  });

  const filteredPlots = useFilters<T>({
    data: searchedData ?? [],
    key: 'filters-precision',
  });

  const tableColumns = useColumns<T>(columns);

  const toggleCollapse = () => {
    if (expanded) {
      searchParams.delete('expanded');
    } else {
      searchParams.set('expanded', 'true');
    }
    setSearchParams(searchParams);
  };

  const isCollapsible = !!cardRows?.length;

  const isExpanded = expanded || !isCollapsible;

  const TableComponent = virtualized ? DataGridVirtualized : DataGrid;

  const handleSelectTableRow = (item: T) => {
    if (selectKey) {
      searchParams.delete('expanded');
      searchParams.set(selectKey ?? '', item.id);
      setSearchParams(searchParams);
    }

    return onSelect?.(item);
  };

  const selectedId = searchParams.get(selectKey ?? '') ?? undefined;

  const tableRef = useRef<TableVirtuosoHandle>(null);

  useScrollToSelection(tableRef, filteredPlots, selectedId);

  return (
    <GenericErrorBoundary>
      {search && <Search className={classes.search} {...search} />}
      {filters && <Filters filters={filters} />}
      {isCollapsible && (
        <Box
          className={classes.collapseIcon}
          onClick={toggleCollapse}
          left={isExpanded ? 'calc(100% - 30px)' : '465px'}
        >
          <Beta.Icon icon={isExpanded ? 'chevron-left' : 'chevron-right'} />
        </Box>
      )}

      <Box
        style={style}
        className={clsx(
          classes.table,
          isExpanded && classes.expanded,
          !isCollapsible && classes.notCollapsible
        )}
      >
        {header && <Header {...header} />}

        {loading && (
          <Center h={style?.height}>
            <Loader data-testid="table-loader" />
          </Center>
        )}

        {isExpanded ? (
          <TableComponent
            tableRef={tableRef}
            columns={tableColumns}
            data={filteredPlots}
            height="calc(100dvh - 130px)"
            horizontalSpacing={16}
            onSelect={handleSelectTableRow}
            onSort={handleSort}
            sortStatus={sort}
            verticalSpacing={8}
            selectedId={selectedId}
          />
        ) : (
          <CardList<T>
            cardRows={cardRows}
            columns={columns}
            data={filteredPlots}
            onSelect={onSelect}
            selectKey={selectKey}
            renderCardBorderRight={renderCardBorderRight}
          />
        )}
        {/* TODO check empty table at collapsed version */}
        {data.length === 0 && <EmptyTable {...empty} />}
      </Box>

      {aside}

      {sidePanel && (
        <SidePanel<T> selectKey={selectKey} data={data} {...sidePanel} />
      )}
    </GenericErrorBoundary>
  );
};

const DateCell = <T,>(props: CellProps<T>) => {
  const value = props.getValue();
  const date = typeof value === 'string' ? formatDate(value) : '---';

  return <Text>{date}</Text>;
};

NewTable.SidePanel = SidePanel;
NewTable.DateCell = DateCell;
export default NewTable;
