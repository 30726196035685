import { Beta, Button, Menu, StyledTooltip } from '@liveeo/component-library';
import classes from '../../../shared/styles/Menu.module.css';
import clsx from 'clsx';

export type ActionItem = {
  label?: string;
  onClick: (e: React.MouseEvent) => void;
};

type SingleActionItem = {
  icon?: Parameters<typeof Beta.Icon>[0]['icon'];
  tooltip?: string;
  onClick: () => void;
};

type ActionsMenuProps = {
  list: ActionItem[];
  icon?: Parameters<typeof Beta.Icon>[0]['icon'] | null;
  offset?: number;
  position?: 'left-start' | 'left-end' | 'right-start' | 'right-end';
  singleAction?: SingleActionItem;
  buttonStyle?: React.CSSProperties;
  buttonLabel?: string;
  dataTestId?: string;
};

const SingleAction = ({ item }: { item: SingleActionItem }) => {
  return (
    <StyledTooltip label={item?.tooltip} dark={true}>
      <Button
        variant="transparent"
        size="sm"
        style={{ padding: 0 }}
        onClick={() => {
          if (typeof item?.onClick === 'function') {
            item.onClick();
          }
        }}
      >
        <Beta.Icon icon={item.icon ?? 'close'} />
      </Button>
    </StyledTooltip>
  );
};

const ActionsMenu = ({
  list,
  icon = 'ellipsis-vertical',
  offset = -20,
  position = 'left-start',
  singleAction,
  buttonStyle,
  buttonLabel,
  dataTestId = 'action-menu-button',
}: ActionsMenuProps) => {
  return (
    <Menu offset={offset} position={position} additionalClassNames={classes}>
      {singleAction ? (
        <SingleAction item={singleAction} />
      ) : (
        <>
          <Menu.Target>
            <Button
              variant={buttonLabel ? 'outline' : 'transparent'}
              size={buttonLabel ? 'md' : 'sm'}
              className={clsx(!list?.length && classes.hidden)}
              style={buttonStyle}
              data-testid={dataTestId}
            >
              {icon && <Beta.Icon icon={icon} />}
              {buttonLabel && <Beta.Text>{buttonLabel}</Beta.Text>}
            </Button>
          </Menu.Target>
          <Menu.Dropdown bg="dark.9" opacity={0.9}>
            <>
              {list.map((item) => (
                <Menu.Item onClick={item.onClick} key={item.label}>
                  {item.label}
                </Menu.Item>
              ))}
            </>
          </Menu.Dropdown>
        </>
      )}
    </Menu>
  );
};

export default ActionsMenu;
