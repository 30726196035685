import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlots, useToggleAside, useTracking } from '../../../hooks';
import {
  Modal,
  Title,
  Image,
  Flex,
  Text,
  Icon,
  Box,
} from '@liveeo/component-library';
import { SidePanelContainer } from '../SidePanelContainer';
import { PlotsImport } from '../../Plots/PlotsImport';
import { Table } from '../../../shared/components/Table';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../../common/constants';
import UploadCloud from '../../../../assets/upload-cloud.svg';
import classes from './PlotsTablePanel.module.css';
import { PlotsTable } from '../../Plots/PlotsTable';
import AddPlots from './AddPlots';
import Search, { useSearch } from '../../../shared/components/NewTable/Search';
import { Plot } from '../../../shared/types';

const EmptyTable = () => {
  const { t } = useTranslation();
  const { toggleAside } = useToggleAside();

  return (
    <Table.EmptyState>
      <Flex justify="center" align="center" gap="md">
        <Image src={UploadCloud} width={25} />
        <Icon icon="draw-polygon" size="xl" />
      </Flex>
      <Title ta="center" order={3}>
        {t('plots.table.noData.title')}
      </Title>
      <Text ta="center">{t('plots.table.noData.text')}</Text>
      <Table.EmptyState.Footer handleClose={() => toggleAside(undefined)} />
    </Table.EmptyState>
  );
};

export const PlotsTablePanel = () => {
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(
    window.innerHeight - (FOOTER_HEIGHT + HEADER_HEIGHT)
  );
  const { data: plots, isLoading } = usePlots();
  const { trackEvent } = useTracking();
  const isEmpty = !isLoading && !plots?.length;

  const searchedPlots = useSearch<Plot>({
    searchKey: 'search',
    data: plots ?? [],
    keys: ['name'],
  });

  useEffect(() => {
    if (isImportModalOpen) {
      trackEvent('Map', {
        step: 'import-plots',
        action: 'init',
      });
    }
  }, [trackEvent, isImportModalOpen]);

  const toggleImportModal = (boolean: boolean) => {
    if (!isButtonDisabled) setIsImportModalOpen(boolean);
  };

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - (FOOTER_HEIGHT + HEADER_HEIGHT));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [height]);

  return (
    <>
      <SidePanelContainer>
        <SidePanelContainer.Header>
          <SidePanelContainer.Title title={t<string>('plots.title')} />
          <Box className={classes.searchContainer}>
            <Search
              placeholder={t<string>('map.plots.searchPlots')}
              clearable
            />
            <AddPlots />
          </Box>
        </SidePanelContainer.Header>
        {isLoading ? (
          <Table.Loader height={height} />
        ) : isEmpty ? (
          <EmptyTable />
        ) : (
          <PlotsTable plots={searchedPlots} height={height} />
        )}
      </SidePanelContainer>
      <Modal
        size={500}
        opened={isImportModalOpen}
        onClose={() => toggleImportModal(false)}
        title={
          <div>
            <Title order={3}>{t('plots.import.title')}</Title>
          </div>
        }
        centered
      >
        <PlotsImport
          isButtonDisabled={isButtonDisabled}
          setIsButtonDisabled={setIsButtonDisabled}
        />
      </Modal>
    </>
  );
};
