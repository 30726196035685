import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SurveyResponse, UpdateSurveyResponseDto } from '../shared/types';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { SurveyModel } from 'survey-core';
import { useTranslation } from 'react-i18next';

type SubmissionFileErrorType =
  | 'ATTACHMENT_INFECTED_BY_MALWARE'
  | 'ATTACHMENT_PENDING_MALWARE_SCANNING';

type SubmissionFileError = {
  payload: { attachmentId: string };
  type: SubmissionFileErrorType;
};

export type SubmissionError = {
  error: string;
  message: { errors: SubmissionFileError[]; statusCodes: number };
};

type SurveyResponseData = {
  surveyModel: SurveyModel | null;
  countryCode: string | undefined;
  attachmentIds: string[];
};

const useParseSubmissionError = () => {
  const { t } = useTranslation();

  const parseSubmissionError = (error: SubmissionError) => {
    const errorTypes = error?.message?.errors?.map(({ type }) => type);

    const errorMessage = {
      ATTACHMENT_INFECTED_BY_MALWARE: t('documents.errors.infectedByMalware'),
      ATTACHMENT_PENDING_MALWARE_SCANNING: t(
        'documents.errors.pendingMalwareScan'
      ),
    };
    const DEFAULT_ERROR = t('documents.errors.fileSubmissionError');

    // if there are both types return message for ATTACHMENT_INFECTED_BY_MALWARE
    if (errorTypes?.includes('ATTACHMENT_INFECTED_BY_MALWARE')) {
      return errorMessage.ATTACHMENT_INFECTED_BY_MALWARE;
    }
    if (errorTypes?.includes('ATTACHMENT_PENDING_MALWARE_SCANNING')) {
      return errorMessage.ATTACHMENT_PENDING_MALWARE_SCANNING;
    }

    return DEFAULT_ERROR;
  };

  return { parseSubmissionError };
};

export const useSurveyResponse = (surveyResponseId?: string) => {
  const fetch = useFetchApi();
  const { parseSubmissionError } = useParseSubmissionError();
  const { apiError } = useNotification();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successfullyUpdated, showError } = useNotification();
  const { t } = useTranslation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['survey-response', surveyResponseId],
    queryFn: () =>
      fetch(
        `survey-responses/${surveyResponseId}?includes=survey,attachments`
      ) as Promise<SurveyResponse>,
    enabled: !!surveyResponseId,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const updateSurveyResponse = useMutation<
    SurveyResponse,
    SubmissionError,
    UpdateSurveyResponseDto
  >({
    mutationKey: ['survey-response', surveyResponseId],
    mutationFn: (payload: UpdateSurveyResponseDto) =>
      fetch(`survey-responses/${surveyResponseId}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }) as Promise<SurveyResponse>,
  });

  const onSaveSuccess = async (surveyResponse: SurveyResponse) => {
    // TODO TEUDR-1941 this is wrong. the response data of the mutation does not contain `.survey` and `.respondent`!
    // This is a problem for functionality that absolutely requires respondent and survey to exist, like the pdf download. The table may be all right since we fall back to null if the path does not exist. And once the refetch of survey responses (triggered by queryClient.invalidateQueries) finishes, we are in a good state again. But in the meantime... The same holds for loading an individual survey response. There it's visible when you save a survey response, the heading vanishes (because it contains data of surveyResponse.survey which is undefined).
    queryClient.setQueryData(
      ['survey-responses'],
      (prev: SurveyResponse[] | undefined) =>
        prev?.map((sr) => (sr.id === surveyResponse.id ? surveyResponse : sr))
    );
    queryClient.setQueryData(
      ['survey-response', surveyResponse.id],
      surveyResponse
    );
    queryClient.invalidateQueries({ queryKey: ['survey-responses'] });
    queryClient.invalidateQueries({
      queryKey: ['survey-response', surveyResponse.id],
    });

    successfullyUpdated('survey response');
    navigate('/map/documents?pan=1');
  };

  const onSave = (data: SurveyResponseData) => {
    const payload = {
      json: data.surveyModel?.data,
      countryCode: data.countryCode,
      attachmentIds: data.attachmentIds,
    };

    if (!data.countryCode) {
      showError(t<string>('documents.errors.requiredCountry'));
      return;
    }

    if (!data.surveyModel?.validate()) {
      showError(t('documents.errors.requiredQuestions'));
      return;
    }

    updateSurveyResponse.mutate(payload, {
      onSuccess: onSaveSuccess,
      onError: apiError,
    });
  };

  const onSubmitSuccess = () => {
    successfullyUpdated('survey response');
    navigate('/map/documents?pan=1');
    setShowConfirmationModal(false);
  };

  const onSubmitError =
    (surveyModel: SurveyModel | null) => (error: SubmissionError) => {
      setShowConfirmationModal(false);
      const submissionError = parseSubmissionError(error);

      // we need to get from BE the name of the file field with error
      // for now we are using the first file field
      const fileTypeElementName =
        surveyModel?.pages[0]?.elements.find(
          (element) => element.getType() === 'file'
        )?.name ?? '';

      if (surveyModel) {
        showError(submissionError);
        surveyModel

          .getQuestionByName(fileTypeElementName)
          ?.addError(submissionError);
      }
    };

  const onSaveAndSubmit = (data: SurveyResponseData) => {
    const surveyModel = data.surveyModel;
    const payload = {
      json: surveyModel?.data,
      countryCode: data.countryCode,
      attachmentIds: data.attachmentIds,
    };

    if (!data.countryCode) {
      showError(t<string>('documents.errors.requiredCountry'));
      return;
    }

    if (!data.surveyModel?.validate()) {
      showError(t('documents.errors.requiredQuestions'));
      return;
    }

    updateSurveyResponse.mutate(payload, {
      onSuccess: () => {
        updateSurveyResponse.mutate(
          {
            status: 'COMPLETED',
          },
          {
            onSuccess: onSubmitSuccess,
            onError: onSubmitError(surveyModel),
          }
        );
      },
    });
  };

  return {
    data,
    isLoading: isLoading || updateSurveyResponse.isLoading,
    isFetching,
    updateSurveyResponse,
    showConfirmationModal,
    setShowConfirmationModal,
    onSave,
    onSaveAndSubmit,
  };
};
