import { convertArea } from '@turf/helpers';
import { deforestationRiskMapping } from '../../../helper';
import { Plot, StatusColor, Statuses } from '../../../shared/types';
import { round } from 'lodash';
import { convertFloatToPercentage } from '../../../helper/number-transform';

export type DeforestationState =
  | {
      risk: number | undefined;
      status: Statuses;
      color: StatusColor;
      percentage: string | null;
    }
  | undefined;

export const DEFORESTATION_RISK_COLORS: Record<
  Exclude<Statuses, 'processing'>,
  StatusColor
> = {
  high: '#D4111B',
  medium: '#FFAB1A',
  low: '#512BB5',
};

export const calculateDeforestation = (
  risk: number | undefined
): DeforestationState => {
  const status = deforestationRiskMapping(risk);
  const percentage = risk ? convertFloatToPercentage(risk) : null;
  if (status === 'processing') {
    return undefined;
  }

  return {
    risk,
    status,
    color: DEFORESTATION_RISK_COLORS[status],
    percentage,
  };
};

export const getPlotDeforestation = (
  plot?: Partial<Plot>
): DeforestationState => {
  const value =
    plot?.mostRecentAnalysesByType?.PRECISION?.riskOfDeforestation ??
    plot?.mostRecentAnalysesByType?.EUFOROBS?.riskOfDeforestation;

  return calculateDeforestation(value);
};

const HECTARES_PRECISION = 2;

export const m2ToHa = (value: number | string | undefined | null) => {
  if (value === undefined || value === null) {
    return '---';
  }

  const result = convertArea(Number(value), 'meters', 'hectares');

  if (result < 0.01) {
    return '< 0.01';
  }

  return String(round(result, HECTARES_PRECISION).toFixed(HECTARES_PRECISION));
};
