import { useForm, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Stack,
  Flex,
  ActionIcon,
  Icon,
  Button,
} from '@liveeo/component-library';
import classes from '../Admin.module.css';
import { StringParam, useQueryParam } from 'use-query-params';
import useInvitations, {
  EmailInvitation,
  isApiError,
} from '../../../hooks/useInvitations';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

type FormValues = {
  users: EmailInvitation[];
};

export const InviteUser = () => {
  const [, setInvite] = useQueryParam('invite', StringParam);
  const { sendInvitation } = useInvitations();
  const { t } = useTranslation();

  const defaultValues = {
    email: '',
  };

  const invitationSchema = yup.object().shape({
    users: yup.array().of(
      yup.object().shape({
        email: yup
          .string()
          .email(t<string>('error.invalidEmail'))
          .required(t<string>('error.requiredEmail')),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      users: [{ ...defaultValues }],
    },
    resolver: yupResolver(invitationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const onSubmit = async (formValues: FormValues) => {
    let hasErrors = false;

    await Promise.all(
      formValues.users.map(async (user, index) => {
        try {
          await sendInvitation.mutateAsync(user);
        } catch (error) {
          hasErrors = true;

          if (isApiError(error)) {
            if (error.statusCode === 409) {
              setError(`users.${index}.email`, {
                message: t<string>('invitations.error.existingUser'),
              });
            }
            if (error.statusCode === 422) {
              setError(`users.${index}.email`, {
                message: t<string>('invitations.error.existingInvitation'),
              });
            }
          }
        }
      })
    );

    if (!hasErrors) {
      setInvite(null);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Stack>
        {fields.map((field, index) => (
          <Flex
            key={field.id}
            justify="space-between"
            align="center"
            direction="row"
          >
            <TextInput
              {...register(`users.${index}.email`)}
              error={errors?.users?.[index]?.email?.message}
              placeholder={t<string>('invitations.email')}
              w="90%"
              classNames={{ input: classes.input }}
            />
            {fields.length > 1 ? (
              <ActionIcon
                size="lg"
                onClick={() => remove(index)}
                variant="transparent"
              >
                <Icon icon="trash" />
              </ActionIcon>
            ) : (
              <ActionIcon
                size="lg"
                onClick={() => append({ ...defaultValues })}
                variant="outline"
              >
                <Icon icon="plus" />
              </ActionIcon>
            )}
          </Flex>
        ))}
        {fields.length > 1 && (
          <Flex w="100%" justify="flex-end">
            <ActionIcon
              size="lg"
              onClick={() => append({ ...defaultValues })}
              variant="outline"
            >
              <Icon icon="plus" />
            </ActionIcon>
          </Flex>
        )}
      </Stack>
      <Flex justify="end" gap="sm" mt="sm">
        <Button variant="transparent" size="sm" onClick={() => setInvite(null)}>
          Cancel
        </Button>
        <Button variant="outline" size="sm" type="submit">
          Invite
        </Button>
      </Flex>
    </form>
  );
};
