import { useSupplierPlots, useIntlApi } from '../../../hooks';
import { Table } from '../../../shared/components/Table';
import { Group, Text } from '@mantine/core';
import { squareMetersToHectares } from '@liveeo/helpers';
import { CellProps } from '../../../shared/components/NewTable/useColumns';
import { deforestationDisturbance } from '../../../helper';
import { useTranslation } from 'react-i18next';
import CardList from '../../../shared/components/NewTable/CardList';
import { Plot, BusinessConnection } from '../../../shared/types';
import supplierClasses from './SuppliersTablePanel.module.css';
import useShowPlot from '../../../hooks/useShowPlot';
import {
  PLOT_CARD_ROWS,
  renderPlotCardStatus,
} from '../PlotsTablePanel/NewPlotsTablePanel';
import { m2ToHa } from '../PlotsTablePanel/utils';

const SuppliersPlotTab = (props?: Partial<BusinessConnection>) => {
  const { t } = useTranslation();
  const { getLocalizedCountry } = useIntlApi();
  const showPlot = useShowPlot();

  const businessId = props?.targetBusiness?.id;

  const { data: plots, isLoading } = useSupplierPlots(businessId ?? '');

  if (isLoading) {
    return <Table.Loader />;
  }

  if (!plots?.length) {
    return <div>No plots found</div>;
  }

  const columns = [
    {
      value: 'name',
      cell: ({ row }: CellProps<Plot>) => (
        <Text w={160} size="lg" truncate>
          {row.name}
        </Text>
      ),
    },
    {
      value: 'surfaceArea',
      cardCell: ({ row }: CellProps<Plot>) => (
        <Text size="lg">{`${m2ToHa(row.surfaceArea)} ha`}</Text>
      ),
    },
    {
      value: 'countryCode',
      cell: ({ row }: CellProps<Plot>) => (
        <Text size="md" truncate>
          {getLocalizedCountry(row?.countryCode ?? '')}
        </Text>
      ),
    },
    { value: 'status' },
    {
      value: 'precision',
      cell: ({ row }: CellProps<Plot>) => {
        const risk =
          row?.mostRecentAnalysesByType?.PRECISION?.riskOfDeforestation;
        if (!risk) return <div />;

        return (
          <Group justify="space-between" flex="1">
            <Text size="sm">TradeAware Precision</Text>
            <Text size="sm">
              {t('analysis.deforestation', {
                percentage: deforestationDisturbance(risk),
              })}
            </Text>
          </Group>
        );
      },
    },
    {
      value: 'euforobs',
      cell: ({ row }: CellProps<Plot>) => {
        const risk =
          row?.mostRecentAnalysesByType?.EUFOROBS?.riskOfDeforestation;
        if (!risk) return <div />;

        return (
          <Group justify="space-between" flex="1">
            <Text size="sm">EU Global Forest Cover</Text>
            <Text size="sm">
              {t('analysis.deforestation', {
                percentage: deforestationDisturbance(risk),
              })}
            </Text>
          </Group>
        );
      },
    },
  ];

  return (
    <CardList<Plot>
      data={plots}
      cardRows={PLOT_CARD_ROWS}
      columns={columns}
      listProps={{ className: supplierClasses.plotList }}
      selectKey="plot-id"
      previousKey="supplier-id"
      onSelect={showPlot}
      renderCardBorderRight={renderPlotCardStatus}
    />
  );
};

export default SuppliersPlotTab;
