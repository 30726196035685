import { memo } from 'react';
import { FillLayer, Layer, Source, useMap } from 'react-map-gl';
import { useLayerState, usePlots, useTableState } from '../../hooks';
import { AnalysisType, hasAnalysis } from '../../helper';
import { LAYER_COLORS, SELECTED_PLOT_KEY } from '../../common/constants';

const layerStyle: FillLayer = {
  id: 'some-polygon',
  type: 'fill',
  source: 'precision',
  paint: {
    'fill-opacity': 0.4,
  },
};

const precisionLayerStyle: FillLayer = {
  ...layerStyle,
  id: 'precision-polygon',
  paint: {
    ...layerStyle.paint,
    'fill-color': LAYER_COLORS.precision,
  },
};

const euforobsLayerStyle: FillLayer = {
  ...layerStyle,
  id: 'gfc2020-polygon',
  paint: {
    ...layerStyle.paint,
    'fill-color': LAYER_COLORS.openSource,
  },
};

interface AnalysisLayerProps {
  type: string;
}

const isAnalysisType = (type: string): type is AnalysisType => {
  return ['PRECISION', 'EUFOROBS'].includes(type);
};

export const AnalysisLayer = memo(({ type }: AnalysisLayerProps) => {
  const { isLoading, findPlotById } = usePlots();
  const { tableState } = useTableState();
  const { layerState } = useLayerState();
  const { deforestationMap: map } = useMap();
  const plot = findPlotById(tableState[SELECTED_PLOT_KEY] || '');

  if (!isAnalysisType(type)) {
    console.error(`Invalid analysis type: ${type}`);
    return null;
  }

  const hasAnalysisKind = hasAnalysis(type, plot);
  const typeOfLayer =
    type === 'PRECISION' ? layerState.precision : layerState.gFC2020;

  return tableState[SELECTED_PLOT_KEY] &&
    hasAnalysisKind &&
    !isLoading &&
    map?.getMap() ? (
    <Source
      id={type.toLowerCase()}
      type="geojson"
      data={
        plot?.mostRecentAnalysesByType?.[type as AnalysisType]
          ?.deforestedGeometry || {}
      }
    >
      <Layer
        {...(typeof type === 'string' && type === 'PRECISION'
          ? precisionLayerStyle
          : euforobsLayerStyle)}
        layout={{
          visibility: hasAnalysisKind && typeOfLayer ? 'visible' : 'none',
        }}
      />
    </Source>
  ) : null;
});
