import { Transaction, TransactionStatus } from '../../../shared/types';
import { Button } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { CellProps } from '../../../shared/components/NewTable/useColumns';

const colorStatus: Record<TransactionStatus, string> = {
  AVAILABLE: 'purple',
  DRAFT: 'dark.9',
  REJECTED: 'red',
  SUBMITTED: 'black',
  TO_SUBMIT: 'dark.9',
  WITHDRAWN: 'dark.4',
};

export const StatusButton = ({ row }: CellProps<Transaction>) => {
  const { t } = useTranslation();

  const label = {
    AVAILABLE: t('transactions.status.available'),
    DRAFT: t('transactions.status.draft'),
    REJECTED: t('transactions.status.rejected'),
    SUBMITTED: t('transactions.status.submitted'),
    TO_SUBMIT: t('transactions.status.queued'),
    WITHDRAWN: t('transactions.status.withdrawn'),
  };

  return (
    <Button bg={colorStatus[row.status]} opacity={1} size="xs">
      {label[row.status]}
    </Button>
  );
};
