import { Beta, Button, Box, Flex, Switch } from '@liveeo/component-library';
import { FiltersProps } from '../../../shared/types';
import { useSearchParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import classes from './Filters.module.css';
import { SELECTED_PLOT_KEY } from '../../../common/constants';

type FiltersComponentProps = {
  filters: FiltersProps;
};

type UseFiltersProps<T extends Record<string, unknown>> = {
  key: string;
  data: T[];
};

// Add this interface to define the structure
interface WithAnalyses {
  mostRecentAnalysesByType?: {
    PRECISION: unknown | null;
    [key: string]: unknown;
  };
}

export const useFilters = <
  T extends Record<string, unknown> & Partial<WithAnalyses>
>({
  key,
  data,
}: UseFiltersProps<T>) => {
  const filtersMap: Record<string, (plot: T) => boolean> = {
    'filters-precision': (plot: T) =>
      plot.mostRecentAnalysesByType?.PRECISION !== null,
  };

  const [filter] = useQueryParam(key);
  return filter
    ? data.filter((result) => {
        const filterFn = filtersMap[key];
        return filterFn ? filterFn(result) : false;
      })
    : data;
};

export const Filters = ({ filters }: FiltersComponentProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const expandedFilters = searchParams.get('fp');
  const precisionAnalysis = searchParams.get(filters?.key);

  const handlePrecisionAnalysis = () => {
    if (precisionAnalysis) {
      searchParams.delete(filters?.key);
    } else {
      searchParams.set(filters?.key, 'true');
      searchParams.delete(SELECTED_PLOT_KEY);
    }
    setSearchParams(searchParams);
  };

  const toggleCollapseFilters = () => {
    if (expandedFilters) {
      searchParams.delete('fp');
    } else {
      searchParams.set('fp', 'true');
    }
    setSearchParams(searchParams);
  };

  return (
    <>
      <Box className={classes.filtersButtonWrapper}>
        {precisionAnalysis && <div className={classes.filterButtonIndicator} />}
        <Button
          className={classes.filtersButton}
          onClick={toggleCollapseFilters}
          bg="dark.7"
          radius="sm"
          size="sm"
          p={9}
        >
          <Beta.Icon icon="filter" size="sm" />
        </Button>
      </Box>
      {expandedFilters && (
        <Box className={classes.filtersPanel}>
          <Flex justify="space-between" align="center">
            <Beta.Text variant="h2">Filters</Beta.Text>
            <Button onClick={toggleCollapseFilters} variant="transparent">
              {/* 
              Commented out for now, as we just have 1 filter for now
              <Beta.Text mr="lg" variant="caption">
                {t('plots.filters.clear')}
              </Beta.Text> */}
              <Beta.Icon icon="close" size="md" />
            </Button>
          </Flex>
          <Switch
            checked={!!precisionAnalysis}
            classNames={{ label: classes.label }}
            label={filters?.label}
            labelPosition="left"
            mt="xl"
            onChange={() => handlePrecisionAnalysis()}
            size="md"
          />
        </Box>
      )}
    </>
  );
};
