import { useTranslation } from 'react-i18next';
import { Beta } from '@liveeo/component-library';
import { Plot } from '../../../shared/types';
import SidePanel, {
  SidePanelDetail,
} from '../../../shared/components/NewTable/SidePanel';
import PlotAnalysisTab from './PlotAnalysisTab';
import { SELECTED_PLOT_KEY } from '../../../common/constants';
import { usePlotActions } from '../../Plots/PlotsActionsMenu';

const PlotsSidePanel = ({ plots = [] }: { plots?: Plot[] }) => {
  const { t } = useTranslation();
  const { handleEditPlot, handleDeletePlot, showEdit, showOptions } =
    usePlotActions();

  const actions = (plot?: Plot) => {
    const actions = [];
    if (showOptions(plot)) {
      if (showEdit(plot)) {
        actions.push({
          label: 'Edit',
          onClick: (e: React.MouseEvent) => handleEditPlot(e, plot),
        });
      }

      actions.push({
        label: 'Delete',
        onClick: (e: React.MouseEvent) => handleDeletePlot(e, plot),
      });
    }

    return actions;
  };

  const detailsList: SidePanelDetail<Plot>[] = [
    {
      label: <Beta.Text c="gray.6">{t('plots.table.commodities')}</Beta.Text>,
      key: 'commodities',
      render: (record: Plot) => (
        <Beta.Text truncate>{record?.commodities?.join(', ') ?? ''}</Beta.Text>
      ),
    },
    {
      label: <Beta.Text c="gray.6">Status</Beta.Text>,
      key: 'status',
      render: (record: Plot) => (
        <Beta.Text variant="b1" truncate>
          N/A
        </Beta.Text>
      ),
    },
    {
      label: <Beta.Text c="gray.6">{t('common.roles.supplier')}</Beta.Text>,
      key: 'suppliers',
      render: (record: Plot) => (
        <Beta.Text variant="b1" truncate>
          {record?.suppliers?.[0]?.name}
        </Beta.Text>
      ),
    },
  ];

  const tabList = [
    {
      label: 'Analysis',
      render: (record?: Plot) =>
        record ? <PlotAnalysisTab {...record} /> : null,
    },
  ];
  return (
    <SidePanel<Plot>
      data={plots}
      titleKey="name"
      details={detailsList}
      tabs={tabList}
      selectKey={SELECTED_PLOT_KEY}
      addList={actions}
    />
  );
};

export default PlotsSidePanel;
