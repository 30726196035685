import { memo } from 'react';
import MapGL, {
  GeolocateControl,
  LngLatLike,
  NavigationControl,
  useMap,
} from 'react-map-gl';
import { GeoLocationSearch } from './GeoLocationSearch';
import { DrawPolygon } from './DrawPolygon';
import {
  useHoveredPlotCallback,
  useLayerState,
  useMapViewState,
  usePlots,
  usePlotState,
  useTableState,
} from '../../hooks';
import { Center, Loader } from '@liveeo/component-library';
import PlotsLayer from './PlotsLayer';
import { getBbox, calculatePadding, hasAnalysis } from '../../helper';
import * as Sentry from '@sentry/react';
import { Legend } from './Legend';
import { AnalysisLayer } from './AnalysisLayer';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SELECTED_PLOT_KEY } from '../../common/constants';

export const InteractiveMap = memo(
  ({ draw, onDrawPlot, onDeletePlot }: any) => {
    const state = usePlotState();
    const { viewState, setViewState } = useMapViewState();
    const { deforestationMap: map } = useMap();
    const { findPlotById } = usePlots();
    const { tableState, setTableState } = useTableState();
    const { setLayerState } = useLayerState();
    const isFetchingMapLocation = Object.values(viewState).every(
      (v) => v === undefined
    );
    const {
      hoveredFeatureId,
      clearHoverdFeatureIdMap,
      setHoveredFeatureIdMap,
    } = useHoveredPlotCallback(map);
    const [searchParams] = useSearchParams();
    const isExpanded = searchParams.get('expanded') === 'true';
    const sidePanelParams =
      searchParams.get(SELECTED_PLOT_KEY) || searchParams.get('supplier-id');

    const location = useLocation();
    const hideLegend =
      location.pathname.endsWith('/transactions') ||
      location.pathname.endsWith('/documents');

    const hideMapControls = hideLegend || isExpanded || sidePanelParams;

    return isFetchingMapLocation ? (
      <Center>
        <Loader />
      </Center>
    ) : (
      <MapGL
        reuseMaps
        id="deforestationMap"
        logoPosition="bottom-right"
        style={{ width: '100%', height: '100vh', marginLeft: 57 }}
        initialViewState={viewState}
        cursor={state?.cursor}
        mapStyle="mapbox://styles/app-team/cldyjkcoo006w01kght7pw3ey"
        mapboxAccessToken={import.meta.env['NX_EUDR_MAPBOX_ACCESS_TOKEN']}
        attributionControl={false}
        // onResize={handleResize}
        // Add layer ids here if you want to use them in the interactive map methods (onClick, onMouseEnter etc.)
        interactiveLayerIds={['plot-fill']}
        onMouseMove={(e) => setHoveredFeatureIdMap(e, hoveredFeatureId)}
        onMouseLeave={(e) => clearHoverdFeatureIdMap(e, hoveredFeatureId)}
        onMoveEnd={({ viewState: { longitude, latitude, zoom } }) =>
          setViewState({ longitude, latitude, zoom })
        }
        onClick={(e: any) => {
          if (state?.enableClick) {
            if (e.features.length) {
              const plot = findPlotById(e.features[0].properties.plotId);

              if (plot && plot.id !== tableState[SELECTED_PLOT_KEY]) {
                const bbox = getBbox(plot.geometry);
                if (bbox.length) {
                  map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
                    duration: 0,
                    padding: calculatePadding(),
                  });
                }
                setTableState({ [SELECTED_PLOT_KEY]: plot.id });

                // We toggle the precision analysis layer visibility when relevant.
                setLayerState({
                  precision: hasAnalysis('PRECISION', plot) ? true : false,
                });
              }
            } else {
              // Reset table state and turn precision layer off when user clicks outside of plot-fill layer
              setLayerState({ precision: false });
              setTableState({
                [SELECTED_PLOT_KEY]: undefined,
                ex: undefined,
              });
            }
          }
        }}
        onError={(e) => Sentry.captureException(e.error)}
      >
        {!hideMapControls && (
          <>
            <GeoLocationSearch position="bottom-right" />
            <GeolocateControl position="bottom-right" />
            <NavigationControl position="bottom-right" showCompass={true} />

            <DrawPolygon
              draw={draw}
              onDrawPlot={onDrawPlot}
              onDeletePlot={onDeletePlot}
            />
          </>
        )}

        {!hideLegend && <Legend />}
        <PlotsLayer />
        <AnalysisLayer type="PRECISION" />
        <AnalysisLayer type="EUFOROBS" />
      </MapGL>
    );
  }
);
