import { FC } from 'react';
import { Flex, Text, Beta } from '@liveeo/component-library';
import classes from './FilePreviewComponent.module.css';
import { ReactElementFactory } from 'survey-react-ui';

type FileItem = {
  name: string;
  type: string;
  content: string;
};

type Question = {
  value: FileItem[];
  removeFile: (fileName: string) => void;
};

type FilePreviewComponentProps = {
  question: Question;
};

const FilePreviewComponent: FC<FilePreviewComponentProps> = ({ question }) => {
  //when no files have been uploaded, question.value is null, but when a user uploads files and then deletes all of them, the value is an array that contains the method, onArrayChanged
  //to allow access to the upload button again, we need to check if the value is an empty array to stop the component from rendering
  if (!question || !question.value || question.value.length === 0) {
    return null;
  }
  return (
    <Flex className={classes.previewContainer}>
      {question.value.map((fileItem) => (
        <div key={fileItem.name} className={classes.itemWrapper}>
          <Beta.Icon icon="file-text" size="xxl" />
          <Text truncate="end" w="100%">
            {fileItem.name}
          </Text>
          <Beta.Icon
            className={classes.delete}
            icon="circle-xmark"
            size="xl"
            onClick={() => question.removeFile(fileItem.name)}
          />
        </div>
      ))}
    </Flex>
  );
};

ReactElementFactory.Instance.registerElement('sv-file-preview', (props) => {
  return <FilePreviewComponent {...props} />;
});

export { FilePreviewComponent };
