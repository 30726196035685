import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntlApi, useTracking } from '../../hooks';
import { useSurveyResponses } from '../../hooks/useSurveyResponses';
import { useSurveys } from '../../hooks/useSurveys';
import { SurveyResponse } from '../../shared/types';
import NewTable from '../../shared/components/NewTable/NewTable';
import { CellValue } from '../../shared/components/NewTable/useColumns';
import { capitalize } from 'lodash';
import { DocumentsTableOwnerCell } from '../Documents/components/DocumentsTableOwnerCell';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { useDownloadDocument } from '../Documents/hooks/useDownloadDocument';
import ActionsMenu from '../../shared/components/NewTable/ActionMenu';

export const DocumentsTablePanel = () => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { data: surveys } = useSurveys();
  const { data: surveyResponses, isLoading: isLoadingSurveysResponses } =
    useSurveyResponses();
  const { getLocalizedCountry } = useIntlApi();
  const navigate = useNavigate();
  const { data: user } = useUser();
  const canDownloadResponse = user?.entitlements?.canDownloadSurveyResponses;
  const { downloadDocument } = useDownloadDocument();

  useEffect(() => {
    trackEvent('Documents', {
      step: 'documents-page',
      action: 'open',
    });
  }, [trackEvent]);

  const edit = (row: SurveyResponse) => ({
    label: t('documents.table.actions.edit'),
    onClick: () => navigate(`/documents/${row.id}`),
  });

  const view = (row: SurveyResponse) => ({
    label: t('documents.table.actions.view'),
    onClick: () => navigate(`/documents/${row.id}`),
  });

  const download = (row: SurveyResponse) => ({
    label: t<string>('documents.table.actions.download'),
    onClick: () => downloadDocument(row.id),
  });

  const setActions = (row: SurveyResponse) => {
    if (row.status === 'COMPLETED') {
      return canDownloadResponse ? [view(row), download(row)] : [view(row)];
    }

    return [edit(row)];
  };

  const columns = [
    { label: t('documents.table.headers.name'), value: 'survey.name' },
    {
      label: t('documents.table.headers.country'),
      value: 'countryCode',
      renderText: (value: CellValue) =>
        getLocalizedCountry(
          value as string,
          t<string>('documents.table.values.countryCodeNotAssigned')
        ),
    },
    {
      label: t('documents.table.headers.owner'),
      value: 'respondent.name',
      cell: DocumentsTableOwnerCell,
    },
    {
      label: t('documents.table.headers.status'),
      value: 'status',
      renderText: (value: CellValue) => capitalize(value as string),
    },
    {
      label: t('documents.table.headers.updatedAt'),
      value: 'updatedAt',
      cell: NewTable.DateCell,
    },
    {
      value: 'actions',
      setActions,
    },
  ];

  const addSurveyList =
    surveys?.map((survey) => ({
      label: t<string>('documents.table.createNewSurveyResponse', {
        name: survey.name,
      }),
      onClick: () => {
        navigate(`/documents/new/${survey.id}`);
      },
    })) ?? [];

  return (
    <NewTable<SurveyResponse>
      header={{
        title: t<string>('documents.table.title'),
        action: <ActionsMenu list={addSurveyList} icon="plus" />,
      }}
      data={surveyResponses}
      columns={columns}
      loading={isLoadingSurveysResponses}
      empty={{
        title: t<string>('documents.table.empty.noData'),
        subtitle: t<string>('documents.table.empty.info'),
        buttonLabel: t<string>('documents.table.empty.callToAction'),
        addList: addSurveyList,
      }}
      search={{
        keys: ['survey.name', 'status'],
        placeholder: 'Search documents by name or status',
      }}
    />
  );
};
