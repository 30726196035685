import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  useStartAnalysis,
  usePlots,
  useResetParams,
  useUser,
} from '../../hooks';
import {
  Button,
  Grid,
  Stack,
  Title,
  Text,
  Image,
  Beta,
  StyledTooltip,
} from '@liveeo/component-library';
import {
  deforestationDisturbance,
  deforestationRiskMapping,
} from '../../helper';
import SUPPLIERS_ICON from '../../../assets/suppliers-icon.svg';
import classes from './CollapsedRow.module.css';
import { SELECTED_PLOT_KEY } from '../../common/constants';

type DeforestationResultsProps = {
  riskScorePrecision: number | undefined;
  riskScoreOpenSource: number | undefined;
  plotId: string;
};

type CollapsedRowProps = DeforestationResultsProps & {
  supplierName: string;
  supplierId: string | null;
  isSupplierView: boolean;
};

const DeforestationResults = ({
  riskScorePrecision,
  riskScoreOpenSource,
  plotId,
}: DeforestationResultsProps) => {
  const { t } = useTranslation();
  const { findPlotById } = usePlots();
  const plot = findPlotById(plotId);
  const { data } = useUser();
  const { mutate: startAnalysis, isLoading } = useStartAnalysis();

  const { canTriggerPrecisionAnalysis } = data?.entitlements ?? {};

  const precisionStatus = plot?.mostRecentAnalysesByType?.PRECISION?.status;
  const showPrecisionResult = typeof riskScorePrecision === 'number';

  const precisionResult = showPrecisionResult
    ? t(`analysis.deforestation`, {
        percentage: deforestationDisturbance(riskScorePrecision),
      })
    : '--';

  const precisionButtonLabel = canTriggerPrecisionAnalysis
    ? t('analysis.start')
    : t('analysis.specialist');

  const handleGetPrecisionAnalyses = () => {
    if (canTriggerPrecisionAnalysis) {
      startAnalysis({ plot, type: 'PRECISION' });

      return;
    }

    window.open(
      'https://live-eo.com/tradeaware-contact',
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <>
      <Grid.Col span={4}>
        <Stack>
          <Text
            ta="center"
            style={{ visibility: showPrecisionResult ? 'visible' : 'hidden' }}
          >
            {precisionResult}
          </Text>

          <Text ta="center">
            {typeof riskScoreOpenSource === 'undefined'
              ? '--'
              : t(`analysis.disturbance`, {
                  percentage: deforestationDisturbance(riskScoreOpenSource),
                })}
          </Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={4}>
        <Stack gap={typeof riskScorePrecision !== 'number' ? 10 : undefined}>
          {precisionStatus ? (
            <StyledTooltip
              label={t('analysis.started.info')}
              style={{ maxWidth: 260, textWrap: 'wrap' }}
              position="right"
              dark
              disabled={precisionStatus !== 'PENDING'}
            >
              <Text className={classes.text}>
                {t(
                  `common.status.${deforestationRiskMapping(
                    riskScorePrecision
                  )}`
                )}

                {precisionStatus === 'PENDING' && (
                  <Beta.Icon icon="circle-info" />
                )}
              </Text>
            </StyledTooltip>
          ) : (
            <Button
              size="compact-sm"
              onClick={handleGetPrecisionAnalyses}
              variant="outline"
              loading={isLoading}
            >
              {precisionButtonLabel}
            </Button>
          )}
          <Text
            className={classes.text}
            data-hidden={typeof riskScoreOpenSource === 'undefined'}
          >
            {t(
              `common.status.${deforestationRiskMapping(riskScoreOpenSource)}`
            )}
          </Text>
        </Stack>
      </Grid.Col>
    </>
  );
};

export const CollapsedRow = ({
  riskScorePrecision,
  riskScoreOpenSource,
  supplierName,
  supplierId,
  plotId,
  isSupplierView,
}: CollapsedRowProps) => {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const { resetParams } = useResetParams();

  return supplierId && plotId ? (
    <Stack className={classes.root}>
      <Text size="sm" fs="italic">
        {t('analysis.title')}
      </Text>
      <Grid grow>
        <Grid.Col span={4}>
          <Stack>
            <Title order={6}>{t('analysis.layers.precision')}</Title>
            <Title order={6}>{t('analysis.layers.global')}</Title>
          </Stack>
        </Grid.Col>
        <DeforestationResults
          riskScorePrecision={riskScorePrecision}
          riskScoreOpenSource={riskScoreOpenSource}
          plotId={plotId}
        />
      </Grid>
      {!isSupplierView && supplierId !== user?.businessId && (
        <Button
          leftSection={<Image src={SUPPLIERS_ICON} w={15} />}
          className={classes.button}
          component={Link}
          to={`../suppliers/${supplierId}?${resetParams()}${SELECTED_PLOT_KEY}=${plotId}`}
        >
          {supplierName}
        </Button>
      )}
    </Stack>
  ) : null;
};
