import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Title,
  Text,
  SimpleGrid,
  Stack,
  Center,
  Loader,
} from '@liveeo/component-library';
import { SidePanelContainer } from '../Map/SidePanelContainer';
import { useParams } from 'react-router-dom';
import { useBuyers, useResetParams, useTracking } from '../../hooks';
import { StatusIndicator } from '../../shared/components/StatusIndicator';
import classes from './BuyerDetailPanel.module.css';
// import { heightOfSidePanelContent } from '../../helper/side-panel-height';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../common/constants';
import { SELECTED_PLOT_KEY } from '../../common/constants';

export const BuyerDetailPanel = () => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { resetParams } = useResetParams();
  const { isLoading, findBuyerById } = useBuyers();
  const { buyerId } = useParams();
  const buyer = findBuyerById(buyerId || '');
  const [height, setHeight] = useState<number>(
    window.innerHeight - (FOOTER_HEIGHT + HEADER_HEIGHT)
  );

  useEffect(() => {
    trackEvent('Buyer', {
      step: 'buyer-details',
      action: 'open',
    });
  }, [trackEvent]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - (FOOTER_HEIGHT + HEADER_HEIGHT));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [height]);

  return isLoading ? (
    <Center h={height}>
      <Loader />
    </Center>
  ) : buyer ? (
    <SidePanelContainer>
      <SidePanelContainer.Header>
        <SidePanelContainer.TitleWithNav
          path={`../buyers?${resetParams()}&${SELECTED_PLOT_KEY}=${buyerId}`}
          name={buyer.name || ''}
        />
      </SidePanelContainer.Header>
      <div className={classes.info}>
        <SimpleGrid className={classes.grid} cols={2}>
          <Stack gap={0}>
            <Title order={4}>{t('buyer.details.contact')}</Title>
            <Text size="lg">{`${buyer.contactPerson?.firstName || '--'} ${
              buyer.contactPerson?.lastName || '--'
            }`}</Text>
          </Stack>
          <Stack gap={0}>
            <Title order={4}>{t('buyer.details.email')}</Title>
            <Text size="lg">{buyer.contactPerson?.email || '--'}</Text>
          </Stack>
          <Stack gap={0}>
            <Title order={4}>{t('buyer.details.address')}</Title>
            <Text size="lg">{buyer.addressLine1 || '--'}</Text>
            <Text size="lg">{buyer.addressLine2 || '--'}</Text>
            <Text size="lg">{buyer.addressCity || '--'}</Text>
            <Text size="lg">{buyer.addressCountry || '--'}</Text>
          </Stack>
          {buyer.status && (
            <Stack gap={0}>
              <Title order={4}>{t('buyer.details.status')}</Title>
              <StatusIndicator text={buyer.status} />
            </Stack>
          )}
        </SimpleGrid>
      </div>
    </SidePanelContainer>
  ) : null;
};
