import {
  FiAlertCircle,
  FiAlertTriangle,
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUp,
  FiBell,
  FiCalendar,
  FiCheck,
  FiCheckCircle,
  FiCheckSquare,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsDown,
  FiChevronsLeft,
  FiChevronsRight,
  FiChevronsUp,
  FiChevronUp,
  FiCircle,
  FiClipboard,
  FiCopy,
  FiEdit,
  FiEdit2,
  FiEye,
  FiEyeOff,
  FiFile,
  FiFilePlus,
  FiFileText,
  FiFolder,
  FiFolderPlus,
  FiGlobe,
  FiHelpCircle,
  FiImage,
  FiInfo,
  FiLayers,
  FiList,
  FiMail,
  FiMap,
  FiMapPin,
  FiMaximize2,
  FiMinimize2,
  FiMinus,
  FiMoreHorizontal,
  FiMoreVertical,
  FiPackage,
  FiPhone,
  FiPlus,
  FiPlusCircle,
  FiRefreshCw,
  FiSearch,
  FiSend,
  FiSettings,
  FiSliders,
  FiTrash2,
  FiUpload,
  FiUploadCloud,
  FiUser,
  FiUserPlus,
  FiX,
  FiExternalLink,
  FiXCircle,
} from 'react-icons/fi';

import { FaStop, FaLock } from 'react-icons/fa6';
import { IoFilterOutline } from 'react-icons/io5';
import { LuCalendarCheck2 } from 'react-icons/lu';
import { PiPolygon } from 'react-icons/pi';
import { PiCaretUpDownThin } from 'react-icons/pi';
import { PiExclamationMarkThin } from 'react-icons/pi';
import { PiTreeEvergreenLight } from 'react-icons/pi';
import { SideBySide } from './CustomIcons/SideBySide';
import { SideBySideSelected } from './CustomIcons/SideBySideSelected';
import { SliderView } from './CustomIcons/SliderView';
import { SliderViewSelected } from './CustomIcons/SliderViewSelected';
import { CutBack } from './CustomIcons/CutBack';
import { Axe } from './CustomIcons/Axe';
import { GoogleMap } from './CustomIcons/Googlemap';
import { ResetView } from './CustomIcons/ResetView';
import { Share2 } from './CustomIcons/Share2';
import { Directions } from './CustomIcons/Directions';

export const IconAliases = {
  'arrow-down': FiArrowDown,
  'arrow-left': FiArrowLeft,
  'arrow-right': FiArrowRight,
  'arrow-up-from-bracket': FiUpload,
  'arrow-up': FiArrowUp,
  'calendar-check': LuCalendarCheck2,
  'caret-down': FiChevronDown,
  'caret-sort': PiCaretUpDownThin,
  'caret-up': FiChevronUp,
  'check-circle': FiCheckCircle,
  'check-square': FiCheckSquare,
  'chevron-down': FiChevronDown,
  'chevron-left': FiChevronLeft,
  'chevron-right': FiChevronRight,
  'chevron-up': FiChevronUp,
  'chevrons-down': FiChevronsDown,
  'chevrons-left': FiChevronsLeft,
  'chevrons-right': FiChevronsRight,
  'chevrons-up': FiChevronsUp,
  'circle-exclamation': FiAlertCircle,
  'circle-info': FiInfo,
  'circle-xmark': FiXCircle,
  'cloud-arrow-up': FiUploadCloud,
  'cut-back': CutBack,
  'draw-polygon': PiPolygon,
  'ellipsis-vertical': FiMoreVertical,
  'eye-slash': FiEyeOff,
  'file-plus': FiFilePlus,
  'file-text': FiFileText,
  'folder-plus': FiFolderPlus,
  'google-map': GoogleMap,
  'help-circle': FiHelpCircle,
  'layer-group': FiLayers,
  'location-pin': FiMapPin,
  'magnifying-glass': FiSearch,
  'paper-plane': FiSend,
  'pen-to-square': FiEdit,
  'plus-circle': FiPlusCircle,
  'refresh-cw': FiRefreshCw,
  'reset-view': ResetView,
  'side-by-side-selected': SideBySideSelected,
  'side-by-side': SideBySide,
  'slider-view-selected': SliderViewSelected,
  'slider-view': SliderView,
  'sort-down': FiChevronDown,
  'sort-up': FiChevronUp,
  'triangle-exclamation': FiAlertTriangle,
  'upload-cloud': FiUploadCloud,
  'user-plus': FiUserPlus,
  'external-link': FiExternalLink,
  share: Share2,
  axe: Axe,
  bell: FiBell,
  calendar: FiCalendar,
  check: FiCheck,
  circle: FiCircle,
  clipboard: FiClipboard,
  close: FiX,
  copy: FiCopy,
  directions: Directions,
  edit: FiEdit,
  ellipsis: FiMoreHorizontal,
  envelope: FiMail,
  exclamation: PiExclamationMarkThin,
  eye: FiEye,
  file: FiFile,
  filter: IoFilterOutline,
  folder: FiFolder,
  gear: FiSettings,
  globe: FiGlobe,
  image: FiImage,
  list: FiList,
  lock: FaLock,
  map: FiMap,
  maximize2: FiMaximize2,
  minimize2: FiMinimize2,
  minus: FiMinus,
  package: FiPackage,
  pencil: FiEdit2,
  phone: FiPhone,
  plus: FiPlus,
  search: FiSearch,
  settings: FiSettings,
  sliders: FiSliders,
  sort: PiCaretUpDownThin,
  stop: FaStop,
  trash: FiTrash2,
  tree: PiTreeEvergreenLight,
  upload: FiUpload,
  user: FiUser,
  xmark: FiX,
};
