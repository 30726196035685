import { useMemo } from 'react';
import ActionsMenu, { ActionItem } from './ActionMenu';
import { Text } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

export type CellValue = string | number | null | undefined;

export type CellProps<T> = {
  row: T;
  getValue: () => CellValue;
};

export type Column<T> = {
  align?: 'left' | 'right' | 'center';
  className?: string;
  cell?: (cellProps: CellProps<T>) => JSX.Element;
  cardCell?: (cellProps: CellProps<T>) => JSX.Element;
  label?: string;
  renderText?: (value: CellValue) => string;
  setActions?: (row: T) => ActionItem[];
  sort?: boolean;
  translationPath?: string;
  value: string;
};

export type DataGridColumn<T> = {
  accessor: string | string | symbol;
  align?: 'left' | 'right' | 'center';
  cell: (props: CellProps<T>) => JSX.Element;
  hasSorting: boolean;
  header: string;
  id: string;
};

const useColumns = <T,>(columns: Column<T>[]): DataGridColumn<T>[] => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      columns.map(
        ({
          align,
          cell,
          className,
          label,
          renderText = (value) => value,
          setActions,
          sort,
          translationPath,
          value,
        }) => ({
          accessor: value,
          className: className ?? '',
          id: value,
          cell: ({ row, getValue }) => {
            if (typeof cell === 'function') {
              return cell({ row, getValue });
            }

            if (typeof setActions === 'function') {
              return (
                <ActionsMenu
                  list={setActions(row)}
                  dataTestId={`${(row as any).id}-row-action-menu`}
                />
              );
            }
            const rowValue = translationPath
              ? t<string>(`${translationPath}.${getValue()}`)
              : get(row, value);

            return <Text>{renderText(rowValue)}</Text>;
          },
          header: label ?? ' ',
          hasSorting: !!sort,
          align: setActions ? 'right' : align,
        })
      ),
    [columns, t]
  );
};

type CardColumn<T> = {
  value: string;
  cardCell?: (cellProps: CellProps<T>) => JSX.Element;
  renderText?: (value: CellValue) => string;
};

export const useCardColumns = <T,>(columns: Column<T>[]): CardColumn<T>[] => {
  return columns.map(({ value, cardCell, cell, renderText }) => {
    return {
      value: value,
      renderText,
      cardCell: cardCell ?? cell,
    };
  });
};

export default useColumns;
