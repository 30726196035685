import { format } from 'date-fns';

export const toDateTime = (
  date: string | Date,
  locales: string[] | readonly string[]
) =>
  new Intl.DateTimeFormat([...locales], {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(new Date(date));

export const toDateOnly = (
  date: string | Date,
  locales: string[] | readonly string[]
) =>
  new Intl.DateTimeFormat([...locales], {
    dateStyle: 'medium',
  }).format(new Date(date));

export const DATE_FORMAT = 'MMM dd, yyyy';

export const formatDate = (date: string | Date, dateFormat = DATE_FORMAT) => {
  return format(new Date(date), dateFormat);
};
