import { Flex, ScrollArea, Table } from '@mantine/core';
import { TableVirtuoso } from 'react-virtuoso';
import { Beta } from '../..';
import { TableContainerStyled } from './DataGrid.styled';
import { DataGridProps, DataGridVirtualizedProps } from './DataGrid.interface';
import get from 'lodash.get';
import { createElement, forwardRef } from 'react';

export const DataGrid = ({
  columns,
  data,
  height = '100%',
  horizontalSpacing = 'sm',
  keyExtractor = (_, index) => `${index}`,
  onSelect = () => ({}),
  onSort = () => ({}),
  sortStatus,
  verticalSpacing = 'sm',
  ...props
}: DataGridProps) => {
  return (
    <TableContainerStyled height={height} {...props}>
      <ScrollArea
        h="100%"
        offsetScrollbars
        p={0}
        styles={{
          viewport: {
            paddingRight: 0,
          },
        }}
      >
        <Table
          borderColor={'dark.9'}
          highlightOnHover
          horizontalSpacing={horizontalSpacing}
          stickyHeader
          verticalSpacing={verticalSpacing}
        >
          <Table.Thead>
            <Table.Tr bg="dark.9">
              {columns.map((column, index) => (
                <Table.Th
                  onClick={() => {
                    if (column.hasSorting) {
                      if (sortStatus === '') {
                        onSort(column.accessor);
                      } else if (sortStatus === column.accessor) {
                        onSort('-' + String(column.accessor));
                      } else {
                        onSort(column.accessor);
                      }
                    }
                  }}
                  style={{ cursor: column.hasSorting ? 'pointer' : 'default' }}
                  key={`table-th-${index}`}
                >
                  <Flex
                    align="center"
                    direction="row"
                    gap={20}
                    justify={column.align ?? 'left'}
                  >
                    <Beta.Text variant="b1" fw="bold">
                      {column.header}
                    </Beta.Text>
                    {column.hasSorting && (
                      <Beta.Icon
                        color={(() => {
                          if (
                            sortStatus === column.accessor ||
                            sortStatus === `-${String(column.accessor)}`
                          )
                            return 'dark.5';
                          return 'white';
                        })()}
                        icon={(() => {
                          if (sortStatus === column.accessor)
                            return 'arrow-down';
                          if (sortStatus === `-${String(column.accessor)}`)
                            return 'arrow-up';
                          return 'arrow-down';
                        })()}
                        size="sm"
                      />
                    )}
                  </Flex>
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.map((row, rowIndex) => (
              <Table.Tr
                key={`table-tr-${keyExtractor(row, rowIndex)}`}
                onClick={() => onSelect(row.id)}
                style={{
                  // mantine <Table highlightOnHoverColor="red"> is not working due to higher specificity of :where(tr)[data-hover]. See https://github.com/mantinedev/mantine/issues/5909
                  '--tr-hover-bg': 'var(--mantine-color-dark-8)',
                }}
              >
                {columns.map((column, colIndex) => (
                  <Table.Td
                    key={`table-td-${colIndex}`}
                    ta={column.align ?? 'left'}
                  >
                    {column.cell ? (
                      <column.cell
                        column={column}
                        getValue={() =>
                          get(row, column.accessor, column.fallback ?? null)
                        }
                        row={row}
                      />
                    ) : (
                      <Beta.Text variant="b2" ta={column.align ?? 'left'}>
                        {/* allow nested property access. if not found, fallback to null */}
                        {get(row, column.accessor, column.fallback ?? null)}
                      </Beta.Text>
                    )}
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </TableContainerStyled>
  );
};

export const DataGridVirtualized = ({
  columns,
  data,
  height,
  horizontalSpacing = 'sm',
  onSelect,
  onSort,
  sortStatus,
  selectedId,
  verticalSpacing = 'sm',
  tableRef,
  ...props
}: DataGridVirtualizedProps) => {
  return (
    <TableContainerStyled height={height} {...props}>
      <TableVirtuoso
        ref={tableRef}
        style={{ height }}
        data={data}
        fixedHeaderContent={() => (
          <Table.Tr bg="dark.9">
            {columns.map((column, index) => (
              <Table.Th
                key={index}
                onClick={() => {
                  if (column.hasSorting) {
                    if (sortStatus === '') {
                      onSort?.(column.accessor);
                    } else if (sortStatus === column.accessor) {
                      onSort?.('-' + String(column.accessor));
                    } else {
                      onSort?.(column.accessor);
                    }
                  }
                }}
                style={{ cursor: column.hasSorting ? 'pointer' : 'default' }}
              >
                <Flex align="center" gap={4}>
                  <Beta.Text variant="b1" fw="bold">
                    {column.header}
                  </Beta.Text>
                  {column.hasSorting && (
                    <Beta.Icon
                      size="sm"
                      icon={(() => {
                        if (sortStatus === column.accessor) return 'arrow-down';
                        if (sortStatus === `-${String(column.accessor)}`)
                          return 'arrow-up';
                        return 'arrow-down';
                      })()}
                      color={(() => {
                        if (
                          sortStatus === column.accessor ||
                          sortStatus === `-${String(column.accessor)}`
                        )
                          return 'dark.5';
                        return 'white';
                      })()}
                    />
                  )}
                </Flex>
              </Table.Th>
            ))}
          </Table.Tr>
        )}
        itemContent={(index, row) => (
          <>
            {columns.map((column, colIndex) => (
              <Table.Td
                className={column.className ?? ''}
                key={colIndex}
                ta={column.align ?? 'left'}
              >
                {column.cell ? (
                  createElement(column.cell, {
                    row,
                    column,
                    getValue: () => get(row, column.accessor),
                  })
                ) : (
                  <Beta.Text variant="b2" ta={column.align ?? 'left'}>
                    {get(row, column.accessor, column.fallback ?? null)}
                  </Beta.Text>
                )}
              </Table.Td>
            ))}
          </>
        )}
        components={{
          Table: ({ children, ...props }) => (
            <Table
              borderColor={'dark.9'}
              highlightOnHover
              horizontalSpacing={horizontalSpacing}
              stickyHeader
              verticalSpacing={verticalSpacing}
              {...props}
            >
              {children}
            </Table>
          ),
          TableRow: ({ children, item, ...props }) => (
            <Table.Tr
              onClick={() => onSelect?.(item)}
              {...props}
              style={{
                '--tr-hover-bg': 'var(--mantine-color-dark-8)',
                backgroundColor: selectedId === item.id ? '#6B666F' : undefined,
              }}
            >
              {children}
            </Table.Tr>
          ),
          TableHead: forwardRef(({ children, ...props }, ref) => (
            <Table.Thead {...props} ref={ref}>
              {children}
            </Table.Thead>
          )),
          TableBody: forwardRef(({ children, ...props }, ref) => {
            return (
              <Table.Tbody {...props} ref={ref}>
                {children}
              </Table.Tbody>
            );
          }),
        }}
      />
    </TableContainerStyled>
  );
};
