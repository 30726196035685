import {
  Beta,
  Box,
  Button,
  Card,
  Group,
  Text,
} from '@liveeo/component-library';
import { StatusIndicator } from '../../../shared/components/StatusIndicator';
import { useTranslation } from 'react-i18next';
import classes from '../../../shared/components/NewTable/NewTable.module.css';
import { usePlots, useStartAnalysis, useUser } from '../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { SELECTED_PLOT_KEY } from '../../../common/constants';
import { Plot } from '../../../shared/types';
import { calculateDeforestation } from './utils';

type DeforestationAnalysis = {
  deforestedArea: number;
  riskOfDeforestation: number;
};

type AnalysisCardProps = {
  deforestationAnalysis: DeforestationAnalysis | null | undefined;
  name: string;
  type: 'precision' | 'euforobs';
};

const AnalysisCard = ({
  deforestationAnalysis,
  name,
  type,
}: AnalysisCardProps) => {
  const { t } = useTranslation();

  const deforestation = calculateDeforestation(
    deforestationAnalysis?.riskOfDeforestation
  );

  const status = deforestation?.status ?? 'processing';
  const percentage = deforestation?.percentage ?? '';

  const deforestedArea = deforestationAnalysis?.deforestedArea;

  return (
    <Card className={classes.card} withBorder mb="md">
      <Group justify="space-between">
        <Box>
          <Text size="lg">{name}</Text>

          {typeof deforestedArea === 'number' && (
            <Text size="sm">
              {type === 'euforobs' && (
                <>
                  {deforestedArea > 0
                    ? t('map.rightSidePanel.disturbance')
                    : t('map.rightSidePanel.noDisturbance')}
                </>
              )}
              {type === 'precision' && (
                <>
                  {deforestedArea > 0
                    ? t('map.rightSidePanel.deforestation')
                    : t('map.rightSidePanel.noDeforestation')}
                </>
              )}
            </Text>
          )}
        </Box>
        <Box>
          <StatusIndicator text={status} size="md" iconSize={12} />
          <Text size="sm" ta="right">
            {percentage}
          </Text>
        </Box>
      </Group>
    </Card>
  );
};

const GetPrecisionCard = () => {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const { canTriggerPrecisionAnalysis } = user?.entitlements ?? {};
  const { mutate: startAnalysis, isLoading } = useStartAnalysis();
  const [searchParams] = useSearchParams();
  const plotId = searchParams.get(SELECTED_PLOT_KEY) ?? '';
  const { findPlotById } = usePlots();
  const plot = findPlotById(plotId);
  const precisionRiskOfDeforestation =
    plot?.mostRecentAnalysesByType?.PRECISION?.riskOfDeforestation;
  const hasPrecision = typeof precisionRiskOfDeforestation === 'number';

  const handleGetPrecisionAnalyses = () => {
    if (canTriggerPrecisionAnalysis && !hasPrecision) {
      startAnalysis({ plot, type: 'PRECISION' });

      return;
    }

    window.open(
      'https://live-eo.com/tradeaware-contact',
      '_blank',
      'noopener,noreferrer'
    );
  };

  const buttonLabel = canTriggerPrecisionAnalysis
    ? t('analysis.start')
    : t('analysis.specialist');

  return (
    <Card className={classes.card} withBorder mb="md">
      <Group justify="space-between" align="top">
        <Box>
          <Text size="lg">Trade Aware Precision</Text>
          <Text size="sm">Get accurate results</Text>
        </Box>
        {!canTriggerPrecisionAnalysis && (
          <Box>
            <Beta.Icon icon="lock" />
          </Box>
        )}
        <Button
          variant="outline"
          size="lg"
          radius="md"
          w="100%"
          onClick={handleGetPrecisionAnalyses}
          loading={isLoading}
        >
          {buttonLabel}
        </Button>
      </Group>
    </Card>
  );
};

const PlotAnalysisTab = (props: Plot) => {
  return (
    <>
      {props?.mostRecentAnalysesByType?.PRECISION && (
        <AnalysisCard
          deforestationAnalysis={props?.mostRecentAnalysesByType?.PRECISION}
          name="TradeAware Precision"
          type="precision"
        />
      )}

      <AnalysisCard
        deforestationAnalysis={props?.mostRecentAnalysesByType?.EUFOROBS}
        name="EU Global Forest Cover"
        type="euforobs"
      />

      {!props?.mostRecentAnalysesByType?.PRECISION && <GetPrecisionCard />}
    </>
  );
};

export default PlotAnalysisTab;
