import {
  Button,
  Center,
  Group,
  Select,
  Stack,
  Text,
  Title,
} from '@liveeo/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Survey } from 'survey-react-ui';
import { useIntlApi, useNotification } from '../../hooks';
import { useSurvey } from '../../hooks/useSurvey';
import { useSurveyJsModel } from '../../hooks/useSurveyJsModel';
import { useSurveyResponses } from '../../hooks/useSurveyResponses';
import { PageNotFoundPage } from '../../shared/components/PageNotFoundPage';
import { TemporaryLoadingPage } from './TemporaryLoadingPage';
import useUploadDocument from './hooks/useUploadDocument';

export const CreateSurveyResponsePage = () => {
  const { surveyId } = useParams();
  const { data: survey, isLoading } = useSurvey(surveyId);
  const { t } = useTranslation();
  const { createSurveyResponse } = useSurveyResponses(surveyId, {
    disableGet: true,
  });
  const [country, setCountry] = useState<string | undefined>();
  const [countryError, setCountryError] = useState<string | undefined>();
  const { showError } = useNotification();
  const surveyModel = useSurveyJsModel(survey?.json, undefined, undefined);

  const { attachmentIds } = useUploadDocument(surveyModel);

  const { localizedCountryList } = useIntlApi();

  const save = () => {
    if (!surveyModel) return;
    if (!surveyModel?.validate() || !country) {
      if (!country) {
        setCountryError(
          t<string>('validations.required', {
            field: t('documents.create.country'),
          })
        );
        showError(t<string>('documents.errors.requiredCountry'));
        return;
      }
      showError(t('documents.errors.requiredQuestions'));

      return;
    }

    const surveyResponseJson = surveyModel.data;
    // Note: this will navigate away on success
    createSurveyResponse.execute({
      json: surveyResponseJson,
      countryCode: country ?? undefined,
      attachmentIds,
    });
  };

  if (isLoading) return <TemporaryLoadingPage />;
  if (!survey) return <PageNotFoundPage />;
  if (!surveyModel) return <TemporaryLoadingPage />;

  // TODO TEUDR-1696 make this look nice
  return (
    <Center>
      <Stack w="100vw">
        <Stack
          w="100vw"
          maw={'calc(90*(var(--sjs-base-unit, var(--base-unit, 8px))))'}
          mx={'auto'}
        >
          <Title ta="center" order={1} size="h2">
            {survey.name}
          </Title>
          <Text fs={'italic'}>{t('documents.create.info')}</Text>
          <Select
            label={t('documents.create.country')}
            placeholder={t<string>('documents.create.country')}
            data={localizedCountryList}
            searchable
            onChange={(value) => {
              setCountry(value ?? undefined);
              setCountryError(undefined);
            }}
            styles={{
              root: { backgroundColor: 'var(--mantine-color-body)' },
              wrapper: { backgroundColor: 'var(--mantine-color-body)' },
              input: { backgroundColor: 'var(--mantine-color-body)' },
              dropdown: { backgroundColor: 'var(--mantine-color-body)' },
            }}
            error={countryError}
          />
          <Survey model={surveyModel} />
        </Stack>

        <Group justify="flex-end" p="md">
          <Button
            variant="outline"
            w="175px"
            component={Link}
            to={'/map/documents?pan=1'}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="outline"
            w="175px"
            onClick={save}
            loading={createSurveyResponse.isLoading}
          >
            {t('common.saveAndClose')}
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
