import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex } from '@mantine/core';
import {
  TransactionFormData,
  TransactionStatus,
} from '../../../shared/types/Transaction';
import { useFormContext, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNotification, useTransactions } from '../../../hooks';
import { isApiError } from '../../../helper/api';
import { useEffect, useState } from 'react';
import { defer } from 'lodash';

type TransactionFormButtonProps = {
  setValue: UseFormSetValue<TransactionFormData>;
  transactionUrl: string;
};

export const TransactionFormButton = ({
  setValue,
  transactionUrl,
}: TransactionFormButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useFormContext<TransactionFormData>();
  const { createTransaction, submitTransaction } = useTransactions();
  const [submitError, setSubmitError] = useState(false);
  const status = watch('status');
  const { transactionId } = useParams();
  const isNewTransaction = transactionId === 'new';
  const { successfullyCreated, successfullyUpdated } = useNotification();

  const createUpdateNotification = isNewTransaction
    ? successfullyCreated
    : successfullyUpdated;

  useEffect(() => {
    if (submitError && isDirty) {
      setSubmitError(false);
    }
  }, [submitError, isDirty]);

  const onSuccess = () => {
    reset();
    navigate(transactionUrl);
  };

  const handleSaveTransaction = handleSubmit(
    ({ selectedHsCodes, ...transactionData }) => {
      setValue('status', TransactionStatus.DRAFT);
      createTransaction.mutate(transactionData, {
        onSuccess: () => {
          createUpdateNotification('transaction');
          reset();
          navigate(transactionUrl);
        },
      });
    }
  );

  const handleSaveAndSubmitTransaction = handleSubmit(
    ({ selectedHsCodes, ...transactionData }) => {
      createTransaction.mutate(transactionData, {
        onSuccess: (data) => {
          navigate(`/transactions/${data.id}`);

          submitTransaction.mutate(
            { ...transactionData, id: data.id },
            {
              onSuccess,
              onError: (error) => {
                if (isApiError(error) && error.statusCode === 400) {
                  setSubmitError(true);
                  reset();
                }
              },
            }
          );
        },
      });
    }
  );

  const disabled: boolean =
    status === TransactionStatus.SUBMITTED ||
    status === TransactionStatus.TO_SUBMIT ||
    submitError ||
    !watch('components')?.length;

  return (
    <Flex justify="end" gap="sm" mt="lg">
      <Button
        variant="outline"
        size="sm"
        onClick={() => navigate(transactionUrl)}
      >
        {t('common.cancel')}
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={handleSaveTransaction}
        disabled={disabled}
      >
        {t('common.saveAndClose')}
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={handleSaveAndSubmitTransaction}
        disabled={disabled}
      >
        {t('common.submit')}
      </Button>
    </Flex>
  );
};
