import { Checkbox, Select, TextInput } from '@liveeo/component-library';
import { useController, useFormContext } from 'react-hook-form';
import classes from './Inputs.module.css';
import { CheckboxProps, SelectProps, TextInputProps } from '@mantine/core';

type InputProps = TextInputProps &
  CheckboxProps &
  SelectProps & {
    label: string;
    name: string;
    type?: 'text' | 'checkbox' | 'select';
    theme?: 'dark' | 'light';
    onChange?: (
      value: React.ChangeEvent<HTMLInputElement> | string | null
    ) => void;
  };

const BaseInput = ({ label, name, type = 'text', ...props }: InputProps) => {
  const form = useFormContext();
  const { field, fieldState } = useController({
    name,
    control: form?.control,
  });

  const Component = {
    text: TextInput,
    checkbox: Checkbox,
    select: Select,
  }[type];

  return (
    <Component
      label={label}
      placeholder={label}
      error={fieldState.error?.message}
      {...props}
      {...field}
      onChange={(event) => {
        field.onChange(event);
        if (typeof props?.onChange === 'function') {
          props.onChange(event);
        }
      }}
    />
  );
};

export const Input = ({ theme = 'dark', ...props }: InputProps) => (
  <BaseInput
    type="text"
    className={classes.flexGrow}
    classNames={{
      input:
        theme === 'dark' ? classes.darkBackground : classes.lightBackground,
      label: theme === 'light' ? classes.colorDark7 : '',
    }}
    {...props}
  />
);

export const CheckboxInput = (props: InputProps) => {
  const { watch } = useFormContext();
  return <BaseInput type="checkbox" {...props} checked={watch(props.name)} />;
};

export const SelectInput = ({ theme = 'dark', ...props }: InputProps) => {
  const classNames = {
    light: {
      input: classes.lightBackground,
      label: classes.colorDark7,
      options: classes.lightBackground,
      option: classes.selectOptionLight,
      dropdown: classes.lightBackground,
    },
    dark: {
      input: classes.darkBackground,
      label: classes.darkBackground,
      options: classes.darkBackground,
      option: classes.selectOptionDark,
      dropdown: classes.darkBackground,
    },
  };

  return (
    <BaseInput
      type="select"
      classNames={theme === 'dark' ? classNames.dark : classNames.light}
      data={props.data}
      {...props}
    />
  );
};
