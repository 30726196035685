import styled from '@emotion/styled';
import { Box } from '@mantine/core';

type TableContainerProps = {
  height?: string | number | undefined;
  children?: React.ReactNode;
};

export const TableContainerStyled = styled.div<TableContainerProps>`
  height: ${({ height }) => height};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
