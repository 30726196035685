import { useMantineTheme } from '@liveeo/helpers';
import { useToggleAside } from '../../../hooks';
import {
  Divider,
  Flex,
  Group,
  Icon,
  Text,
  ActionIcon,
  Pagination,
} from '@liveeo/component-library';
import classes from './TableFooter.module.css';
import { useReactTable } from '@tanstack/react-table';

type TableFooterPanelProps<T> = {
  table: ReturnType<typeof useReactTable<T>>;
};

export const TableFooterPanel = <T,>({ table }: TableFooterPanelProps<T>) => {
  const theme = useMantineTheme();
  const { toggleAside } = useToggleAside();

  const activePage = (table.getState().pagination?.pageIndex ?? 0) + 1;

  const rows = table.getRowModel().rows;
  const firstRow = rows[0]?.id ? Number(rows[0].id) + 1 : 0;
  const lastRow = rows.at(-1)?.id ? Number(rows.at(-1)?.id) + 1 : 0;
  const totalRows = table.getPreFilteredRowModel().rows.length;

  const visibleRows = rows.length
    ? `${firstRow}-${lastRow} of ${totalRows}`
    : '0 of 0';

  return (
    <tfoot className={classes.footerPanel}>
      <tr>
        <td>
          <Divider />
          <Flex direction="row-reverse" p="xs" w="545px">
            <ActionIcon
              onClick={() => toggleAside(undefined)}
              bg="transparent"
              data-testid="closeTable"
            >
              <Icon icon="chevron-left" color="#FFF" />
            </ActionIcon>

            <Divider orientation="vertical" mr={10} />
            <Group justify="center" w="100%">
              <Text c={theme.colors.dark[4]} size="xs" mt={5}>
                {visibleRows}
              </Text>
              <Pagination
                size="sm"
                className={classes.pagination}
                value={activePage}
                onChange={(page) => table.setPageIndex(page - 1)}
                total={table.getPageCount()}
                gap={2}
              />
            </Group>
          </Flex>
        </td>
      </tr>
    </tfoot>
  );
};
