import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SidePanelContainer } from '../SidePanelContainer';
import { InviteSuppliers } from '../../Suppliers/InviteSuppliers';
import { SuppliersTable } from '../../Suppliers/SuppliersTable';
import { Button, Stack, Title, Text } from '@liveeo/component-library';
import { useToggle } from '@liveeo/helpers';
import { useSuppliers, useTracking, useToggleAside } from '../../../hooks';
import { Table } from '../../../shared/components/Table';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../../common/constants';
import classes from './SuppliersTablePanel.module.css';

type EmptyTableProps = {
  toggleModal: () => void;
};

const EmptyTable = ({ toggleModal }: EmptyTableProps) => {
  const { t } = useTranslation();
  const { toggleAside } = useToggleAside();

  return (
    <Table.EmptyState>
      <Stack align="center" w="65%" gap={20}>
        <Stack gap={0}>
          <Title ta="center" order={4}>
            {t('suppliers.table.empty.title')}
          </Title>
          <Text ta="center">{t('suppliers.table.empty.text')}</Text>
        </Stack>
        <Button
          variant="outline"
          className={classes.button}
          onClick={toggleModal}
        >
          {t<string>('invite.suppliers.title')}
        </Button>
      </Stack>
      <Table.EmptyState.Footer handleClose={() => toggleAside(undefined)} />
    </Table.EmptyState>
  );
};

export const SuppliersTablePanel = () => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const [isModalOpen, toggleModal] = useToggle();
  const { data: suppliers, isLoading } = useSuppliers();
  const isEmpty = !isLoading && !suppliers?.length;
  const [height, setHeight] = useState<number>(
    window.innerHeight - (FOOTER_HEIGHT + HEADER_HEIGHT)
  );
  useEffect(() => {
    trackEvent('Supplier', {
      step: 'supplier-page',
      action: 'open',
    });
  }, [trackEvent]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - (FOOTER_HEIGHT + HEADER_HEIGHT));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [height]);

  return (
    <SidePanelContainer>
      <SidePanelContainer.Header>
        <SidePanelContainer.Title title={t<string>('suppliers.title')} />
        <Button
          variant="outline"
          size="compact-sm"
          className={classes.button}
          onClick={() => toggleModal()}
        >
          {t<string>('suppliers.invite')}
        </Button>
      </SidePanelContainer.Header>
      {isLoading ? (
        <Table.Loader height={height} />
      ) : isEmpty ? (
        <EmptyTable toggleModal={toggleModal} />
      ) : (
        <SuppliersTable
          height={height}
          suppliers={
            suppliers?.map((supplier) => ({
              ...supplier.targetBusiness,
              plotsCount: supplier.plotsCount,
            })) || []
          }
        />
      )}
      <InviteSuppliers isOpen={isModalOpen} toggleOpen={toggleModal} />
    </SidePanelContainer>
  );
};
