import { useParams } from 'react-router-dom';
import {
  DRAW_POLYGON,
  SELECTED_PLOT_KEY,
  SIMPLE_SELECT,
} from '../common/constants';

type PlotState = {
  mode: string;
  cursor: 'pointer' | 'crosshair' | 'ew-resize';
  enableClick: boolean;
  defaultState: { [name: string]: boolean };
  tableState?: { [name: string]: undefined };
};

type MappedStates = { [state: string]: PlotState };

const defaultLayers = { gFC2020: true, precision: true };

const stateMapping: MappedStates = {
  'plot-details': {
    mode: SIMPLE_SELECT,
    cursor: 'pointer',
    enableClick: true,
    defaultState: { ...defaultLayers, plots: true },
  },
  'draw-plot': {
    mode: DRAW_POLYGON,
    cursor: 'crosshair',
    enableClick: false,
    defaultState: {
      ...defaultLayers,
      plots: false,
      gFC2020: false,
      precision: false,
    },
    tableState: { [SELECTED_PLOT_KEY]: undefined, ex: undefined },
  },
  'edit-plot': {
    mode: SIMPLE_SELECT,
    cursor: 'ew-resize',
    enableClick: false,
    defaultState: {
      ...defaultLayers,
      plots: false,
      gFC2020: false,
      precision: false,
    },
    tableState: { [SELECTED_PLOT_KEY]: undefined, ex: undefined },
  },
  'delete-plot': {
    mode: SIMPLE_SELECT,
    cursor: 'pointer',
    enableClick: false,
    defaultState: {
      ...defaultLayers,
      plots: true,
      gFC2020: false,
      precision: false,
    },
    tableState: { [SELECTED_PLOT_KEY]: undefined, ex: undefined },
  },
};

/*
  This hook controls the various states when interacting with a plot
    - plot-details (a user can see and click on plot polygons and toggle layers on/off)
    - draw-plot (a user can not see other plots, can draw new plots)
    - edit_plot (a user can edit the plot they have selected)
    - delete_plot (a user can delete the plot they have selected)
*/
export const usePlotState = () => {
  const url = useParams()['slug'];
  return url ? stateMapping[url] : stateMapping['plot-details'];
};
