import { useState } from 'react';
import {
  Badge,
  Beta,
  Checkbox,
  Flex,
  Stack,
  TextInput,
} from '@liveeo/component-library';
import {
  Controller,
  Control,
  FieldValues,
  Path,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HsCode } from '../../../shared/types/Transaction';
import Fuse from 'fuse.js';
import { useQueryParam } from 'use-query-params';
import { HsCodeFormButtons } from './HsCodesFormButtons';
import classes from '../../../shared/components/Inputs/Inputs.module.css';

const BadgeWithText = ({
  badgeAttributes = {},
  description,
  hsCode,
  textAttributes = {},
}: {
  badgeAttributes?: Record<string, unknown>;
  description: string;
  hsCode: string;
  textAttributes?: Record<string, unknown>;
}) => {
  return (
    <Flex>
      <Badge
        color="dark.8"
        size="md"
        radius="xs"
        {...badgeAttributes}
        styles={{ label: { overflow: 'initial', textOverflow: 'initial' } }}
      >
        {hsCode}
      </Badge>
      <Beta.Text ml={10} variant="b3" {...textAttributes}>
        {description}
      </Beta.Text>
    </Flex>
  );
};

const styles = {
  input: {
    backgroundColor: 'transparent',
  },
};

export const HsCodeSelect = <T extends FieldValues>({
  control,
  hsCodes,
  name,
  setValue,
  setIsModalOpen,
  selectedHsCodes,
}: {
  control: Control<T>;
  hsCodes: HsCode[];
  name: Path<T>;
  setValue: UseFormSetValue<T>;
  setIsModalOpen: (value: boolean) => void;
  selectedHsCodes: string[];
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<string[]>([]);
  const [search, setSearchQuery] = useQueryParam<string>('search');

  // fusejs config documentation https://www.fusejs.io/api/options.html
  const fuse = new Fuse<HsCode>(hsCodes, {
    keys: ['commodity', 'description', 'hsCode'],
    threshold: 0.1,
    useExtendedSearch: true,
  });

  const searchedHsCodes = search
    ? fuse.search(search).map((res) => res.item)
    : hsCodes;

  return (
    <Stack>
      <TextInput
        defaultValue={search}
        label={t('transactions.form.searchHsCodes')}
        leftSection={<Beta.Icon icon="search" />}
        mb="md"
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={t('transactions.form.searchHsCodePlaceholder') ?? ''}
        classNames={{
          input: classes.darkBackground,
        }}
      />
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <Checkbox.Group {...field}>
              <Stack>
                {searchedHsCodes
                  ?.filter((item) => item.commodity !== null)
                  ?.sort(
                    (a, b) => parseInt(a.hsCode, 10) - parseInt(b.hsCode, 10)
                  )
                  ?.map(
                    (item) =>
                      !item.parentHsCode && (
                        <Stack key={item.hsCode}>
                          <Flex
                            align="top"
                            gap="sm"
                            onClick={() =>
                              isOpen.includes(item.hsCode)
                                ? setIsOpen(
                                    isOpen.filter((i) => i !== item.hsCode)
                                  )
                                : setIsOpen([...isOpen, item.hsCode])
                            }
                          >
                            <Beta.Icon
                              icon={
                                isOpen.includes(item.hsCode)
                                  ? 'chevron-up'
                                  : 'chevron-down'
                              }
                            />
                            <BadgeWithText
                              hsCode={item.hsCode}
                              description={item.description}
                              textAttributes={{ style: { flex: 1 } }}
                            />
                          </Flex>
                          {isOpen.includes(item.hsCode) && (
                            <Stack>
                              {hsCodes
                                .filter(
                                  (child) => child.parentHsCode === item.hsCode
                                )
                                .map((child) => {
                                  const hasChildren = hsCodes.some(
                                    (grandchild) =>
                                      grandchild.parentHsCode === child.hsCode
                                  );
                                  return (
                                    <Stack
                                      key={child.hsCode}
                                      ml={hasChildren ? 0 : 35}
                                    >
                                      <Flex align="top" gap="sm">
                                        {hasChildren && (
                                          <Beta.Icon
                                            icon={
                                              isOpen.includes(child.hsCode)
                                                ? 'chevron-up'
                                                : 'chevron-down'
                                            }
                                            onClick={() => {
                                              isOpen.includes(child.hsCode)
                                                ? setIsOpen(
                                                    isOpen.filter(
                                                      (i) => i !== child.hsCode
                                                    )
                                                  )
                                                : setIsOpen([
                                                    ...isOpen,
                                                    child.hsCode,
                                                  ]);
                                            }}
                                          />
                                        )}
                                        <Checkbox
                                          value={child.hsCode}
                                          label={
                                            <BadgeWithText
                                              badgeAttributes={{ ml: 5 }}
                                              description={child.description}
                                              hsCode={child.hsCode}
                                            />
                                          }
                                          styles={styles}
                                        />
                                      </Flex>
                                      {isOpen.includes(child.hsCode) && (
                                        <Stack>
                                          {hsCodes
                                            .filter(
                                              (grandchild) =>
                                                grandchild.parentHsCode ===
                                                child.hsCode
                                            )
                                            .map((grandchild) => (
                                              <Checkbox
                                                key={grandchild.hsCode}
                                                value={grandchild.hsCode}
                                                ml={35}
                                                label={
                                                  <BadgeWithText
                                                    hsCode={grandchild.hsCode}
                                                    description={
                                                      grandchild.description
                                                    }
                                                    badgeAttributes={{
                                                      ml: 55,
                                                    }}
                                                  />
                                                }
                                                styles={styles}
                                              />
                                            ))}
                                        </Stack>
                                      )}
                                    </Stack>
                                  );
                                })}
                            </Stack>
                          )}
                        </Stack>
                      )
                  )}
              </Stack>
            </Checkbox.Group>
            <HsCodeFormButtons
              field={field}
              hsCodes={hsCodes}
              setIsModalOpen={setIsModalOpen}
            />
          </>
        )}
      />
    </Stack>
  );
};
