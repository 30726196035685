import { useMemo } from 'react';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  BooleanParam,
} from 'use-query-params';
import { SELECTED_PLOT_KEY } from '../common/constants';

export const useTableState = () => {
  const [query, setQuery] = useQueryParams({
    pg: typeof NumberParam, // page index
    ex: typeof NumberParam, // expanded index
    [SELECTED_PLOT_KEY]: typeof StringParam, // selected id
    sort: typeof StringParam, // sorting name
    desc: typeof BooleanParam, // is sorting desc
  });

  const sorting = useMemo(
    () =>
      query.sort && typeof query.desc === 'boolean'
        ? [{ id: query.sort, desc: query.desc }]
        : [],
    [query.sort, query.desc]
  );

  const expanded = {
    // tableState.ex will return undefined when it is not present in the params
    ...(typeof query.ex === 'number' && { [query.ex]: true }),
  };

  return {
    tableState: {
      pg: query.pg,
      ex: query.ex,
      [SELECTED_PLOT_KEY]: query[SELECTED_PLOT_KEY],
      sort: query.sort,
      desc: query.desc,
    },
    setTableState: (stateUpdate: object) => {
      setQuery({ ...stateUpdate });
    },
    sorting,
    expanded,
  };
};
