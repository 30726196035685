import { useCallback, useMemo } from 'react';
import { WORLD_COUNTRY_LIST } from '../../assets/countryList';

/**
 * On Firefox, the output language will be determined by the language setting within the browser itself.
 * On Chrome, it is determined by your Operating System's language setting.
 */
const useIntlCountryName = () =>
  useCallback((countryCode: string, fallback = '') => {
    try {
      const regionNames = new Intl.DisplayNames(undefined, {
        type: 'region',
      });
      return regionNames.of(countryCode) || fallback;
    } catch (error) {
      if (error instanceof Error) {
        // it seems firefox and chrome have different error messages, so only checking the name
        const notAValidCountryCode = error.name === 'RangeError';
        if (notAValidCountryCode) {
          return fallback;
        }
      }
      throw error;
    }
  }, []);

const useIntlDateTimeFormat = () =>
  useCallback(
    (
      datetime: Date | number | string,
      options?: Intl.DateTimeFormatOptions
    ) => {
      const value =
        typeof datetime === 'string' ? new Date(datetime) : datetime;
      return Intl.DateTimeFormat(undefined, options).format(value);
    },
    []
  );

export const useIntlApi = () => {
  const getLocalizedCountry = useIntlCountryName();
  const getLocalizedDatetimeFormat = useIntlDateTimeFormat();
  const localizedCountryList = useMemo(
    () =>
      WORLD_COUNTRY_LIST.map((item) => ({
        label: getLocalizedCountry(item.value, item.label),
        value: item.value,
      })).sort((a, b) => (a.label >= b.label ? 1 : -1)),
    [getLocalizedCountry]
  );
  return {
    getLocalizedCountry,
    getLocalizedDatetimeFormat,
    localizedCountryList,
  };
};
