import { Beta, Stack, Button } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import ActionsMenu, { ActionItem } from './ActionMenu';

export type EmptyTableProps = {
  onClick?: () => void;
  entity?: string;
  action?: string;
  icon?: Parameters<typeof Beta.Icon>[0]['icon'];
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
  addList?: ActionItem[];
};

export const EmptyTable = ({
  onClick,
  entity,
  action,
  icon = 'file',
  title,
  subtitle,
  buttonLabel,
  addList,
}: EmptyTableProps) => {
  const { t } = useTranslation();
  const entityLabel = entity ?? t('common.data');
  const actionLabel = action ?? t('common.add');

  return (
    <Stack align="center" pt={80}>
      <Beta.Icon icon={icon} />
      <Beta.Text fw="bold">
        {title ?? t('table.empty', { entity: entityLabel })}
      </Beta.Text>
      {subtitle && <Beta.Text>{subtitle}</Beta.Text>}

      {addList ? (
        <ActionsMenu
          list={addList}
          buttonLabel={buttonLabel}
          icon={null}
          offset={10}
          position="right-end"
        />
      ) : (
        <Button variant="outline" onClick={onClick}>
          {buttonLabel ??
            t('table.addAction', { action: actionLabel, entity: entityLabel })}
        </Button>
      )}
    </Stack>
  );
};
