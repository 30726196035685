import { Icon, Text, Group, Center } from '@liveeo/component-library';
import { Statuses, StatusColor } from '../types';
import { useTranslation } from 'react-i18next';
import { DEFORESTATION_RISK_COLORS } from '../../pages/Map/PlotsTablePanel/utils';

type Props = {
  text: Statuses;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  iconSize?: number;
  width?: string;
};

type IconProps = {
  color: StatusColor;
  size?: number;
};

const StatusIcon = ({ color, size = 8 }: IconProps) => (
  <Icon icon="circle" width={size} color={color} />
);

const StatusText = ({ text, size = 'sm' }: Props) => (
  <Text c="white" size={size} fw={400} span>
    {text}
  </Text>
);

export const StatusIndicator = ({
  text,
  size = 'sm',
  iconSize = 8,
  width,
}: Props) => {
  const { t } = useTranslation();
  switch (text) {
    case 'high':
      return (
        <Group justify="center" gap="xs" w={width}>
          <StatusIcon color={DEFORESTATION_RISK_COLORS.high} size={iconSize} />
          <StatusText text={t('common.status.high')} size={size} />
        </Group>
      );
    case 'low':
      return (
        <Group justify="center" gap="xs" w={width}>
          <StatusIcon color={DEFORESTATION_RISK_COLORS.low} size={iconSize} />
          <StatusText text={t('common.status.low')} size={size} />
        </Group>
      );
    case 'medium':
      return (
        <Group justify="center" gap="xs" w={width}>
          <StatusIcon
            color={DEFORESTATION_RISK_COLORS.medium}
            size={iconSize}
          />
          <StatusText text={t('common.status.medium')} size={size} />
        </Group>
      );
    case 'processing':
      return (
        <Center>
          <StatusText text={t('common.status.processing')} size={size} />
        </Center>
      );
    default:
      return <StatusText text={text} size={size} />;
  }
};
