import {
  useMutation,
  useQueryClient,
  useQuery,
  InfiniteData,
} from '@tanstack/react-query';
import { useFetchApi } from './useFetchApi';
import { UpdatePlot } from '../shared/types';
import { Plot } from '../shared/types';
import { useNotification } from './useNotification';
import { booleanEqual } from '@turf/turf';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStartAnalysis } from './useStartAnalysis';

export const usePlot = (plotId?: string) => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError, successfullyUpdated, successfullyDeleted } =
    useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: startAnalysis } = useStartAnalysis();

  const { data, isLoading } = useQuery({
    queryKey: ['plot', plotId],
    queryFn: () => fetch(`plots/${plotId}`) as Promise<Plot>,
    enabled: !!plotId,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const updatePlot = useMutation({
    mutationKey: ['plot', plotId],
    mutationFn: (payload: UpdatePlot) =>
      fetch(`plots/${plotId}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }),
    onSuccess: (plot: Plot) => {
      successfullyUpdated('plot');
      const previousPlot = queryClient
        .getQueryData<InfiniteData<Plot[]>>(['plots'])
        ?.pages.flat()
        .find((p) => p.id === plot.id);
      const updatedPlot = { ...previousPlot, ...plot };

      startAnalysis({ plot: updatedPlot, type: 'EUFOROBS' });

      queryClient.setQueryData<InfiniteData<Plot[]>>(['plots'], (prevPlots) => {
        if (!prevPlots) return prevPlots;
        const newPages = prevPlots.pages.map((page) =>
          page.map((p) => (p.id === plot.id ? updatedPlot : p))
        );
        return {
          ...prevPlots,
          pages: newPages,
        };
      });
    },
    onError: apiError,
  });

  const deletePlot = useMutation({
    mutationKey: ['plot'],
    mutationFn: (plotId: string) =>
      fetch(`plots/${plotId}`, {
        method: 'DELETE',
      }),
    onSuccess: (_, plotId) => {
      successfullyDeleted('plot');
      queryClient.setQueryData<InfiniteData<Plot[]>>(['plots'], (prevPlots) => {
        if (!prevPlots) return prevPlots;
        const newPages = prevPlots.pages.map((page) =>
          page.filter((p) => p.id !== plotId)
        );
        return {
          pageParams: prevPlots.pageParams,
          pages: newPages,
        };
      });
      navigate(
        {
          pathname: '/map/plots',
          search: location.search,
        },
        {
          replace: true,
        }
      );
      queryClient.invalidateQueries({ queryKey: ['plots'] });
    },
    onError: apiError,
  });

  return { data, deletePlot, isLoading, updatePlot };
};
