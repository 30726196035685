import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlots, useTracking } from '../../../hooks';
import { Modal, Title, Text, Group, Icon } from '@liveeo/component-library';
import { PlotsImport } from '../../Plots/PlotsImport';
import NewTable from '../../../shared/components/NewTable/NewTable';
import {
  CellProps,
  CellValue,
} from '../../../shared/components/NewTable/useColumns';
import { useQueryParam } from 'use-query-params';
import { Plot } from '../../../shared/types';
import { truncate } from 'lodash';
import { WORLD_COUNTRY_LIST } from '../../../../assets/countryList';
import PlotsSidePanel from './PlotsSidePanel';
import useShowPlot from '../../../hooks/useShowPlot';
import AddPlots from './AddPlots';
import { SELECTED_PLOT_KEY } from '../../../common/constants';
import classes from './PlotsTablePanel.module.css';
import { m2ToHa, getPlotDeforestation, calculateDeforestation } from './utils';

const DeforestationRisk = ({ row }: { row: Plot }) => {
  const { t } = useTranslation();
  const deforestation = calculateDeforestation(row.risk);
  const status = deforestation?.status ?? 'processing';

  return (
    <Group
      justify="center"
      gap="xs"
      w="fit-content"
      title={deforestation?.percentage ?? t<string>('analysis.noDeforestation')}
    >
      {deforestation?.color && (
        <Icon icon="circle" width={8} color={deforestation?.color} />
      )}
      <Text c="white" size="sm" fw={400} span>
        {t(`common.status.${status}`)}
      </Text>
    </Group>
  );
};

export const PLOT_CARD_ROWS = [
  ['name', 'surfaceArea'],
  ['commodities', 'status'],
  ['countryCode', 'suppliers.[0].name'],
];

export const renderPlotCardStatus = (plot: Plot) => {
  const deforestation = getPlotDeforestation(plot);

  return `8px solid ${deforestation?.color}`;
};

const NewPlotsTablePanel = () => {
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [importModalOpen, setImportModal] = useQueryParam('import-modal');

  const { data: plots, isLoading } = usePlots();

  const { trackEvent } = useTracking();
  const showPlot = useShowPlot();

  useEffect(() => {
    if (importModalOpen) {
      trackEvent('Map', {
        action: 'init',
        step: 'import-plots',
      });
    }
  }, [trackEvent, importModalOpen]);

  const columns = [
    {
      className: classes.fixedWidth,
      cell: ({ row }: CellProps<Plot>) => (
        <Text title={row.name}>{truncate(row.name, { length: 50 })}</Text>
      ),
      cardCell: ({ row }: CellProps<Plot>) => (
        <Text size="lg" title={row.name}>
          {truncate(row.name, { length: 30 })}
        </Text>
      ),
      label: t('plots.table.name'),
      sort: true,
      value: 'name',
    },
    {
      renderText: (value: CellValue) => truncate(String(value), { length: 50 }),
      label: t('plots.table.commodities'),
      sort: true,
      value: 'commodities',
    },
    {
      renderText: (value: CellValue) =>
        WORLD_COUNTRY_LIST?.find((country) => country.value === value)?.label ??
        String(value),
      label: t('map.sidePanel.details.properties.country'),
      sort: true,
      value: 'countryCode',
    },
    {
      label: t('common.roles.supplier'),
      sort: true,
      value: 'suppliers.[0].name',
    },
    {
      renderText: (value: CellValue) => m2ToHa(value),
      cardCell: ({ row }: CellProps<Plot>) => (
        <Text size="lg">{`${m2ToHa(row.surfaceArea)} ha`}</Text>
      ),
      label: `${t('plots.table.surfaceArea')} (ha)`,
      sort: true,
      value: 'surfaceArea',
    },
    {
      cell: NewTable.DateCell,
      label: t('plots.table.updatedAt'),
      sort: true,
      value: 'updatedAt',
    },

    {
      cell: DeforestationRisk,
      label: t('plots.table.status'),
      sort: true,
      value: 'risk',
    },
  ];

  return (
    <>
      <NewTable<Plot>
        header={{
          action: <AddPlots />,
          title: t<string>('plots.title'),
        }}
        data={plots ?? []}
        columns={columns}
        loading={isLoading}
        empty={{
          entity: t('plots.title').toLowerCase(),
          icon: 'user-plus',
        }}
        cardRows={PLOT_CARD_ROWS}
        onSelect={showPlot}
        virtualized
        search={{
          keys: ['name', 'commodities', 'suppliers.name'],
          placeholder: t<string>('map.plots.searchPlots'),
        }}
        filters={{
          label: t<string>('plots.filters.precision'),
          key: 'filters-precision',
        }}
        selectKey={SELECTED_PLOT_KEY}
        aside={<PlotsSidePanel plots={plots ?? []} />}
        renderCardBorderRight={renderPlotCardStatus}
      />
      <Modal
        centered
        opened={importModalOpen === 'open'}
        onClose={() => setImportModal(undefined)}
        size={500}
        title={<Title order={3}>{t('plots.import.title')}</Title>}
      >
        <PlotsImport
          isButtonDisabled={isButtonDisabled}
          setIsButtonDisabled={setIsButtonDisabled}
        />
      </Modal>
    </>
  );
};

export default NewPlotsTablePanel;
