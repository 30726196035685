import { useLocation } from 'react-router-dom';
import { SELECTED_PLOT_KEY, SELECTED_SUPPLIER_KEY } from '../common/constants';

export const useResetParams = () => {
  const location = useLocation();

  const resetParams = () => {
    const searchParams = new URLSearchParams(location.search);
    // reset table state when changing route
    searchParams.set('pg', '0');
    searchParams.set('pan', '1');
    searchParams.delete('ex');
    searchParams.delete('sort');
    searchParams.delete('desc');
    searchParams.delete('status');
    searchParams.delete(SELECTED_PLOT_KEY);
    searchParams.delete(SELECTED_SUPPLIER_KEY);
    searchParams.delete('search');
    searchParams.delete('filters-precision');
    return `${searchParams.toString()}`;
  };

  return { resetParams };
};
