import { Beta } from '@liveeo/component-library';
import SidePanel, {
  SidePanelDetail,
} from '../../../shared/components/NewTable/SidePanel';
import { Supplier } from '../../../shared/types';
import SuppliersPlotTab from './SuppliersPlotsTab';
import { RiskAssessmentStatus } from './RiskAssessmentStatus';
import { useSearchParams } from 'react-router-dom';

const SupplierSidePanel = ({ data = [] }: { data?: Supplier[] }) => {
  const [searchParams] = useSearchParams();
  const supplierId = searchParams.get('supplier-id') || '';
  const supplier = data?.find((supplier) => supplier.id === supplierId);
  const details = [
    { label: <Beta.Text c="gray.6">Company name</Beta.Text>, key: 'name' },
    {
      label: <Beta.Text c="gray.6">Status</Beta.Text>,
      key: 'supplierName',
      render: (record: Supplier) => (
        <RiskAssessmentStatus supplierId={supplier?.targetBusiness.id || ''} />
      ),
    },
    { label: <Beta.Text c="gray.6">Contact</Beta.Text>, key: 'contactPerson' },
  ];

  const tabs = [
    {
      label: 'Plots',
      render: (record?: Supplier) => <SuppliersPlotTab {...record} />,
    },
  ];
  return (
    <SidePanel
      titleKey="name"
      details={details as SidePanelDetail<Supplier>[]}
      tabs={tabs}
      data={data}
      selectKey="supplier-id"
    />
  );
};

export default SupplierSidePanel;
