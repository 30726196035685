import {
  Button,
  Center,
  Group,
  Stack,
  Text,
  Title,
} from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import { useIntlApi } from '../../hooks';
import { useSurveyJsModel } from '../../hooks/useSurveyJsModel';
import { useSurveyResponse } from '../../hooks/useSurveyResponse';
import { PageNotFoundPage } from '../../shared/components/PageNotFoundPage';
import { SubmissionConfirmationModal } from './components/SubmissionConfirmationModal';
import { TemporaryLoadingPage } from './TemporaryLoadingPage';
import useUploadDocument from './hooks/useUploadDocument';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectInput } from '../../shared/components/Inputs/Inputs';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  countryCode: yup.string().required('Country is required'),
});

export const EditSurveyResponsePage = () => {
  const { surveyResponseId } = useParams();
  const { t } = useTranslation();
  const { localizedCountryList } = useIntlApi();

  const {
    data: surveyResponse,
    isLoading,
    showConfirmationModal,
    setShowConfirmationModal,
    onSave,
    onSaveAndSubmit,
  } = useSurveyResponse(surveyResponseId);

  const surveyModel = useSurveyJsModel(
    surveyResponse?.survey?.json,
    surveyResponse?.json,
    surveyResponse?.status
  );

  const form = useForm({
    values: {
      countryCode: surveyResponse?.countryCode,
    },
    resolver: yupResolver(schema),
  });

  const countryCode = form.watch('countryCode');
  const { attachmentIds } = useUploadDocument(surveyModel);

  const handleSave = () => {
    form.trigger();
    onSave({
      countryCode,
      attachmentIds,
      surveyModel,
    });
  };

  const handleSubmit = () => {
    form.trigger();
    onSaveAndSubmit({
      countryCode,
      attachmentIds,
      surveyModel,
    });
  };

  if (isLoading) return <TemporaryLoadingPage />;
  if (!surveyResponse) return <PageNotFoundPage />;
  if (!surveyModel) return <TemporaryLoadingPage />;

  const isCompleted = surveyResponse.status === 'COMPLETED';

  // TODO TEUDR-1760 make this look nice
  // TODO TEUDR-1760 avoid duplication with CreateSurveyResponsePage
  return (
    <Center>
      <FormProvider {...form}>
        <Stack w="100vw">
          <Stack
            w="100vw"
            maw={'calc(90*(var(--sjs-base-unit, var(--base-unit, 8px))))'}
            mx={'auto'}
          >
            <Title ta="center" order={1} size="h2">
              {surveyResponse?.survey?.name}
            </Title>
            <Text fs={'italic'}>{t('documents.create.info')}</Text>
            <SelectInput
              name="countryCode"
              label={t('documents.create.country')}
              placeholder={t<string>('documents.create.country')}
              data={localizedCountryList}
              searchable
              disabled={isCompleted}
            />

            <Survey model={surveyModel} />
          </Stack>
          <Group justify="flex-end" p="md">
            <Button
              variant="outline"
              w="175px"
              component={Link}
              to={'/map/documents?pan=1'}
            >
              {t('common.cancel')}
            </Button>
            <Button
              variant="outline"
              w="175px"
              onClick={handleSave}
              loading={isLoading}
              display={isCompleted ? 'none' : undefined}
            >
              {t('common.saveAndClose')}
            </Button>
            <Button
              variant="outline"
              w="175px"
              loading={isLoading}
              onClick={() => setShowConfirmationModal(true)}
              display={isCompleted ? 'none' : undefined}
            >
              {t('common.submit')}
            </Button>
          </Group>
        </Stack>
        <SubmissionConfirmationModal
          isOpen={showConfirmationModal}
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={handleSubmit}
          isLoading={isLoading}
        />
      </FormProvider>
    </Center>
  );
};
