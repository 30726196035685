import { useQuery } from '@tanstack/react-query';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';
import { Plot } from '../shared/types';
import { useMap, LngLatLike } from 'react-map-gl';
import { getBbox, calculatePadding } from '../helper';
import { useTableState } from './useTableState';
import { SELECTED_PLOT_KEY } from '../common/constants';
import { useFlags } from '../contexts/FlagsContext';

export const useSupplierPlots = (supplierBusinessId: string) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();
  const { deforestationMap: map } = useMap();
  const { tableState, setTableState } = useTableState();
  const { RISK_MANAGEMENT } = useFlags();

  const fetchSupplierPlots = async (): Promise<Plot[]> => {
    const plots = await fetch(
      `businesses/${supplierBusinessId}/plots?includes=suppliers,mostRecentAnalysesByType`
    );

    return plots;
  };

  const { data, isLoading } = useQuery<Plot[]>({
    queryKey: ['supplier-plots', supplierBusinessId],
    queryFn: fetchSupplierPlots,
    enabled: !!supplierBusinessId,
    onSuccess: (data) => {
      const firstPlot = data?.[0];
      // update map to location of first plot in table with it selected in table
      if (
        !RISK_MANAGEMENT &&
        !tableState.sort &&
        !tableState[SELECTED_PLOT_KEY] &&
        firstPlot
      ) {
        const bbox = getBbox(firstPlot.geometry);
        if (bbox.length) {
          map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
            duration: 0,
            padding: calculatePadding(),
          });
        }
        setTableState({ [SELECTED_PLOT_KEY]: firstPlot.id });
      }
    },
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
