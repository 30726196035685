import { SurveyModel } from 'survey-core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSurveyResponse } from '../../../hooks/useSurveyResponse';
import useAttachments, {
  AttachmentWithFile,
} from '../../../hooks/useAttachments';
import useValidateFileSchema, {
  FileUploadValue,
} from './useValidateFileSchema';
import { useTranslation } from 'react-i18next';

type FileUploadOptions = {
  files: File[];
  callback: (files: unknown[], errors: string[]) => void;
};

type ClearFilesOptions = {
  fileName: string | null;
  value?: FileUploadValue;
  callback: (result: string) => void;
  name: string;
};

const useUploadDocument = (surveyModel: SurveyModel | null) => {
  const { surveyResponseId, surveyId } = useParams();
  const { t } = useTranslation();

  const [attachmentIds, setAttachmentIds] = useState<string[]>([]);
  const { data: surveyResponse } = useSurveyResponse(surveyResponseId);
  const uploadAttachments = useAttachments();
  const validateFileSchema = useValidateFileSchema();

  useEffect(() => {
    if (surveyResponse)
      setAttachmentIds(surveyResponse.attachments?.map(({ id }) => id) ?? []);
  }, [surveyResponse]);

  useEffect(() => {
    if (surveyModel) {
      const avoidDuplicateListeners = () => {
        surveyModel.onUploadFiles.clear();
        surveyModel.onClearFiles.clear();
        surveyModel.onDownloadFile.clear();
      };

      avoidDuplicateListeners();

      const parseError = (responseError: string) => {
        const key = responseError.split(':')[0];

        const errors = {
          INVALID_FILE_MIME_TYPE_ERROR: t('documents.errors.fileType'),
          INVALID_FILE_NAME_LENGTH: t('documents.errors.fileNameLength'),
          INVALID_FILE_EXTENSION_TYPE_ERROR: t(
            'documents.errors.fileExtensionType'
          ),
          MAXIMUM_FILE_SIZE_EXCEEDED: t('documents.errors.maximumFileSize'),
          FILENAME_CONTAINS_FORBIDDEN_CHARACTER_ERROR: t(
            'documents.errors.forbiddenCharacter'
          ),
        };

        const DEFAULT_ERROR = t('documents.errors.fileUploadError');

        return errors[key as keyof typeof errors] ?? DEFAULT_ERROR;
      };

      const handleUploadFiles = async (
        _: unknown,
        options: FileUploadOptions
      ) => {
        await uploadAttachments.mutate(options.files, {
          onSuccess: (data: AttachmentWithFile[]) => {
            const newAttachmentIds = data?.map(
              ({ attachment }) => attachment.id
            );
            setAttachmentIds((prev) => [...prev, ...newAttachmentIds]);
            const surveyData = data?.map(({ file, attachment }) => ({
              file,
              content: { attachmentId: attachment.id },
            }));

            return options.callback(surveyData, []);
          },
          onError: (error) => {
            const errorMessage = parseError(error.message);
            return options.callback([], [errorMessage]);
          },
        });
      };

      const handleClearFiles = async (
        _: unknown,
        options: ClearFilesOptions
      ) => {
        const clearAllButtonClicked = options.fileName === null;

        if (clearAllButtonClicked) {
          // TODO TEUDR-1088 on the create survey response page: when a user clicks delete, we should call DELETE /attachments/:id immediately (there is no survey response that the attachment is part of yet)
          setAttachmentIds([]);
          return options.callback('success');
        }

        const hasValidFile = await validateFileSchema(options.value, {
          surveyId,
          name: options.fileName,
        });

        if (!options.value || !hasValidFile || surveyModel.mode === 'display') {
          return;
        }
        const deletedAttachment = options.value.find(
          (val) => val.name === options.fileName
        );
        // TODO TEUDR-1088 on the create survey response page: when a user clicks delete, we should call DELETE /attachments/:id immediately (there is no survey response that the attachment is part of yet)
        setAttachmentIds((prev) =>
          prev.filter((id) => id !== deletedAttachment?.content.attachmentId)
        );
        return options.callback('success');
      };

      surveyModel.onUploadFiles.add(handleUploadFiles);
      surveyModel.onClearFiles.add(handleClearFiles);
      // TODO TEUDR-2292. Do we even want to allow individual download of files? In any case, enabling this now causes the upload to never show file icon indicating that the file upload finished. For Create, we can just get the file from memory. For Edit: This should probably call GET /attachments?presign=true and then fetch the blob from the GetAttachmentDto.fileUrl + trigger download thereof.
      // surveyModel.onDownloadFile.add(async (_) => {});
    }
  }, [surveyModel, uploadAttachments, validateFileSchema, surveyId, t]);

  return { attachmentIds };
};

export default useUploadDocument;
