import { Icon } from '@liveeo/component-library';
import { Input } from '@mantine/core';
import { useQueryParam, useQueryParams } from 'use-query-params';
import Fuse from 'fuse.js';
import React from 'react';

type UseSearchProps<T> = {
  searchKey: string;
  data: T[];
  keys?: string[];
};

export const useSearch = <T extends Record<string, unknown>>({
  searchKey = 'search',
  data,
  keys,
}: UseSearchProps<T>) => {
  const [search] = useQueryParam(searchKey);
  if (!keys) return data;

  const fuse = new Fuse<T>(data, {
    keys,
    threshold: 0.1,
    distance: 500,
    useExtendedSearch: true,
  });

  return search ? fuse.search(search ?? '').map((result) => result.item) : data;
};

export type SearchProps = {
  searchKey?: string;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
};

const Search = ({
  searchKey = 'search',
  placeholder = 'Search',
  clearable = true,
  className,
}: SearchProps) => {
  const [search] = useQueryParam<string | undefined>(searchKey);
  const [, setQuery] = useQueryParams();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({ [searchKey]: e.target.value, pg: 0 });
  };

  const clearProps = clearable
    ? {
        rightSectionPointerEvents: 'all' as const,
        rightSectionProps: {
          onClick: () => setQuery({ [searchKey]: undefined }),
        },
        rightSection: search ? <Icon icon="close" /> : null,
      }
    : {};

  return (
    <Input
      leftSection={<Icon icon="search" />}
      placeholder={placeholder}
      value={search ?? ''}
      onChange={handleSearch}
      className={className}
      radius="sm"
      styles={{
        input: {
          width: '355px',
          fieldSizing: 'content',
          backgroundColor: 'rgba(31, 29, 34, 0.9)',
          borderColor: 'var(--mantine-color-dark-8)',
        },
      }}
      {...clearProps}
    />
  );
};

export default Search;
