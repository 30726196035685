import { colors } from './theme';
import spinner from '../../assets/spinner.svg?raw';
import { settings, SvgRegistry } from 'survey-core';

/** created using https://surveyjs.io/form-library/documentation/manage-default-themes-and-styles#:~:text=ContrastLight)%3B-,Create%20a%20Custom%20Theme,-SurveyJS%20themes%20use */
export const surveyJsThemeDarkBorderlessPanelless = {
  themeName: 'borderless',
  colorPalette: 'dark',
  isPanelless: true,
  backgroundImage: '',
  backgroundOpacity: 1,
  backgroundImageAttachment: 'scroll',
  backgroundImageFit: 'cover',
  cssVariables: {
    '--sjs-panel-padding': '0px',
    '--sjs-font-editorfont-family': 'var(--mantine-font-family)',
    '--sjs-font-editorfont-color': 'var(--mantine-color-text)',
    '--sjs-font-pagetitle-color': 'var(--mantine-color-text)',
    '--sjs-font-pagetitle-family': 'var(--mantine-font-family)',
    '--sjs-font-questiontitle-color': 'var(--mantine-color-text)',
    '--sjs-font-questiontitle-family': 'var(--mantine-font-family)',
    '--sjs-general-backcolor-dark': 'rgba(50, 55, 72, 1)',
    '--sjs-general-backcolor-dim-dark': 'rgba(40, 46, 61, 1)',
    '--sjs-general-backcolor-dim-light': 'rgba(36, 41, 55, 1)',
    '--sjs-general-backcolor-dim': colors.transparent,
    '--sjs-general-backcolor': 'var(--mantine-color-dark-8)',
    '--sjs-general-forecolor-light': colors.white,
    '--sjs-general-forecolor': 'rgba(255, 255, 255, 0.78)',
    '--sjs-primary-backcolor': colors.white,
    '--sjs-primary-forecolor': 'rgba(32, 32, 32, 1)',
    '--sjs-default-font-family': 'var(--mantine-font-family)',
  },
  headerView: 'basic',
} as const;

export const overrideSurveyJsIcons = () => {
  const customIcons = settings.customIcons as Record<string, string>;

  // default icon list https://surveyjs.io/form-library/documentation/icons#use-custom-svg-icons
  customIcons['icon-clear-24x24'] = 'icon-delete-24x24';
  // default spinner color cannot be customized and new svg is registered
  //https://surveyjs.answerdesk.io/ticket/details/t21719/is-it-possible-to-change-the-loading-color
  SvgRegistry.registerIconFromSvg('icon-loading-48x48', spinner);
};
