import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import { HsCode, Transaction } from '../shared/types';
import { useUser } from './useUser';
export const useTransactions = () => {
  const fetch = useFetchApi();
  const {
    apiError,
    successfullyDeleted,
    successfullySubmitted,
    showErrorWithResponse,
  } = useNotification();

  const {
    data: transactions,
    isLoading: isLoadingTransactions,
    isFetching: isFetchingTransactions,
  } = useQuery({
    queryKey: ['transactions'],
    queryFn: () =>
      fetch(
        'transactions?includes=components.supplierBusiness,components.ddsReferences'
      ) as Promise<Transaction[]>,
    onError: apiError,
    refetchOnWindowFocus: false,

    select: (data) => {
      // sort by latest created descending
      return data.sort((a, b) => {
        return (
          new Date(b.createdAt || '').getTime() -
          new Date(a.createdAt || '').getTime()
        );
      });
    },
  });

  const {
    data: hsCodes,
    isLoading: isLoadingHsCodes,
    isFetching: isFetchingHsCodes,
  } = useQuery({
    queryKey: ['hs-codes'],
    queryFn: () => fetch('components/hs-codes') as Promise<HsCode[]>,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();
  const { transactionId: tId } = useParams();
  const { data: business } = useUser();

  const createTransactionFn = async (
    transactionData: Omit<Transaction, 'id' | 'createdAt' | 'updatedAt'>
  ) => {
    const payload = {
      ...transactionData,
      components: transactionData?.components.map((component) => ({
        ...component,
        commodity: undefined,
        ...(component?.commodity !== 'timber' && { namePairs: undefined }),
      })),
    };

    const transactionId = tId !== 'new' ? tId : '';
    const transaction = queryClient.getQueryData<Transaction>([
      'transaction',
      transactionId,
    ]);
    const removedComponents = transaction?.components?.filter(
      (component) => !payload.components?.some((c) => c.id === component.id)
    );

    if (removedComponents?.length) {
      await Promise.all(
        removedComponents?.map((component) => {
          //delete component
          return fetch(`components/${component.id}`, {
            method: 'DELETE',
          });
        })
      );
    }

    if (transactionId) {
      await Promise.all(
        payload.components?.map((component) => {
          if (component.id) {
            //update component
            return fetch(`components/${component.id}`, {
              method: 'PATCH',
              body: JSON.stringify({
                ...component,
                supplierBusinessId: component.supplierBusiness.id,
                supplierBusiness: undefined,
                id: undefined,
                transactionId: undefined,
                createdAt: undefined,
                updatedAt: undefined,
              }),
            });
          }

          //create new component
          return fetch(`transactions/${transactionId}/components`, {
            method: 'POST',
            body: JSON.stringify({
              ...component,
              supplierBusinessId: component.supplierBusiness.id,
            }),
          });
        }) || []
      );
    }

    return fetch(`transactions/${transactionId}`, {
      method: transactionId ? 'PATCH' : 'POST',
      body: JSON.stringify({
        ...payload,
        components: payload.components?.map((component) => ({
          ...component,
          supplierBusinessId: component.supplierBusiness.id,
          supplierBusiness: undefined,
        })),
        ...(transactionId && {
          components: undefined,
          activityType: undefined,
          selectedHsCodes: undefined,
          status: undefined,
        }),
      }),
    });
  };

  const createTransaction = useMutation({
    mutationKey: ['transactions', business?.businessId],
    mutationFn: createTransactionFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['transactions']);
    },
    onError: showErrorWithResponse,
  });

  const deleteTransaction = useMutation({
    mutationFn: (id: string) =>
      fetch(`transactions/${id}`, {
        method: 'DELETE',
      }),
    onSuccess: (_, id) => {
      successfullyDeleted(`transaction with ID: ${id}`);
      queryClient.invalidateQueries(['transactions']);
    },
    onError: showErrorWithResponse,
  });

  const useTransactionById = (transactionId: string | undefined) =>
    useQuery({
      enabled: !!transactionId && transactionId !== 'new',
      queryKey: ['transaction', transactionId],
      queryFn: () =>
        transactionId !== 'new'
          ? (fetch(`transactions/${transactionId}`) as Promise<Transaction>)
          : Promise.resolve(undefined),
      onError: transactionId !== 'new' ? apiError : undefined,
      refetchOnWindowFocus: false,
    });

  const submitTransactionFn = (
    transactionData: Omit<Transaction, 'updatedAt'>
  ) => {
    const payload = {
      ...transactionData,
      components: transactionData.components.map((component) => ({
        ...component,
        commodity: undefined,
        ...(component?.commodity !== 'timber' && { namePairs: undefined }),
      })),
    };

    return fetch(`transactions/${transactionData?.id ?? tId}/submit`, {
      method: 'PUT',
      body: JSON.stringify({
        ...payload,
        components: payload.components?.map((component) => ({
          ...component,
          supplierBusinessId: component.supplierBusiness.id,
          supplierBusiness: undefined,
        })),
        status: undefined,
        vat: business?.business.vat,
        eori: business?.business.eori,
      }),
    });
  };

  const submitTransaction = useMutation({
    mutationKey: ['transactions', business?.businessId, 'submit'],
    mutationFn: submitTransactionFn,
    onSuccess: () => {
      successfullySubmitted('transaction');
      queryClient.invalidateQueries(['transactions']);
    },
    onError: showErrorWithResponse,
  });

  return {
    createTransaction,
    deleteTransaction,
    hsCodes,
    isFetching: isFetchingTransactions || isFetchingHsCodes,
    isLoading: isLoadingTransactions || isLoadingHsCodes,
    submitTransaction,
    transactions,
    useTransactionById,
  };
};
