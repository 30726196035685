import { useMutation } from '@tanstack/react-query';
import { Attachment } from '../shared/types/Attachment';
import { useFetchApi } from './useFetchApi';

export type AttachmentWithFile = {
  attachment: Attachment;
  file: File;
};

const useAttachments = () => {
  const fetchFileUpload = useFetchApi();

  const uploadAttachments = async (
    files: File[]
  ): Promise<AttachmentWithFile[]> => {
    const attachmentPromises = files.map(async (file) => {
      const formData = new FormData();
      formData.append('file', file);

      const attachment = await fetchFileUpload('attachments', {
        method: 'POST',
        body: formData,
        headers: { 'Content-Type': undefined },
      });

      return {
        attachment,
        file: new File([], attachment.filename, { type: file.type }),
      };
    });

    return Promise.all(attachmentPromises);
  };

  return useMutation<AttachmentWithFile[], Error, File[]>({
    mutationFn: uploadAttachments,
  });
};

export default useAttachments;
