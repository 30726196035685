import { useEffect, useState } from 'react';
import { SidePanelContainer } from './SidePanelContainer';
import { useTranslation } from 'react-i18next';
import { useTracking, useTransactions } from '../../hooks';
import { GenericErrorMessage, Modal, Title } from '@liveeo/component-library';
import { Transaction } from '../../shared/types';
import {
  FiltersBar,
  StatusButton,
  TransactionForm,
  Products,
  SubmissionDate,
} from '../Transactions/Components';
import type { FilterState } from '../Transactions/Components/FiltersBar';
import { useNavigate } from 'react-router-dom';
import NewTable from '../../shared/components/NewTable/NewTable';

export const TransactionsTablePanel = () => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { transactions, isLoading } = useTransactions();
  const { trackEvent } = useTracking();
  const { deleteTransaction } = useTransactions();

  const [filters, setFilters] = useState<FilterState>();

  const filteredTransactions = transactions?.filter((transaction) => {
    if (filters?.activity && transaction.activityType !== filters.activity) {
      return false;
    }
    if (
      filters?.product &&
      !transaction.components?.some((c) => c.commodity === filters.product)
    ) {
      return false;
    }
    if (filters?.status && transaction.status !== filters.status) {
      return false;
    }
    return true;
  });

  const handleFilterChange = (newFilters: Partial<FilterState>) => {
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  const handleCancel = () => setIsImportModalOpen(false);

  useEffect(() => {
    if (isImportModalOpen) {
      trackEvent('Map', {
        step: 'transaction-creation',
        action: 'init',
      });
    }
  }, [trackEvent, isImportModalOpen]);

  const deletion = (row: Transaction) => ({
    label: t('common.delete'),
    onClick: () => deleteTransaction.mutate(row.id || '', {}),
  });

  const edit = (row: Transaction) => ({
    label: t('common.edit'),
    onClick: () => navigate(`/transactions/${row.id}`),
  });

  const view = (row: Transaction) => ({
    label: t('common.view'),
    onClick: () => navigate(`/transactions/${row.id}`),
  });

  const setActions = (row: Transaction) => {
    const actionsByStatus = {
      DRAFT: [edit(row), deletion(row)],
      SUBMITTED: [view(row)],
      TO_SUBMIT: [view(row)],
      AVAILABLE: [view(row)],
      REJECTED: [view(row)],
      WITHDRAWN: [view(row)],
    };

    return actionsByStatus[row.status as keyof typeof actionsByStatus];
  };

  const columns = [
    { value: 'customId', label: t('transactions.columns.customId') },
    {
      value: 'activityType',
      label: t('transactions.columns.activity'),
      sort: true,
    },
    {
      value: 'components',
      label: t('transactions.columns.components'),
      sort: true,
      cell: Products,
    },
    {
      value: 'status',
      label: t('transactions.columns.status'),
      sort: true,
      cell: StatusButton,
    },
    {
      value: 'createdAt',
      label: t('transactions.columns.createdAt'),
      sort: true,
      cell: NewTable.DateCell,
    },
    {
      value: 'updatedAt',
      label: t('transactions.columns.submissionDate'),
      sort: true,
      cell: SubmissionDate,
    },
    {
      value: 'actions',
      setActions,
    },
  ];

  return (
    <GenericErrorMessage>
      <FiltersBar
        onFilterChange={handleFilterChange}
        transactions={transactions || []}
        selectedFilters={filters}
      />

      <NewTable<Transaction>
        header={{
          title: t<string>('transactions.title'),
          onAdd: () => navigate('/transactions/new'),
        }}
        columns={columns}
        data={filteredTransactions || []}
        loading={isLoading}
        empty={{
          entity: 'transactions',
        }}
        search={{
          keys: ['customId', 'activityType', 'components.commodity', 'status'],
          placeholder:
            'search transactions by id, activity, commodity or status',
        }}
      />

      <Modal
        size={500}
        opened={isImportModalOpen}
        onClose={handleCancel}
        title={<Title order={3}>{t('transactions.creation.title')}</Title>}
        centered
      >
        <TransactionForm cancel={handleCancel} />
      </Modal>
    </GenericErrorMessage>
  );
};
