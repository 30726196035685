import { get, sortBy } from 'lodash';
import { useState } from 'react';

const useSort = <T>(data: T[]) => {
  const [sort, setSort] = useState<string>('');

  const handleSort = (columnValue: string | number | symbol) => {
    setSort(String(columnValue));
  };

  let sortedData = [...data];

  if (sort) {
    const field = sort.startsWith('-') ? sort.substring(1) : sort;
    const isDesc = sort.startsWith('-');

    sortedData = sortBy(data, (item: T) => get(item, field));

    if (isDesc) {
      sortedData = sortedData.reverse();
    }
  }

  return {
    sortedData,
    handleSort,
    sort,
  };
};

export default useSort;
