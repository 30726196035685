// NOTE: I could not reproduce a case that schema is not valid.
// The reason this part was modularized was to be easily removed if we confirm that is not used

import { captureException } from '@sentry/react';
import * as yup from 'yup';

export type FileUploadValue = {
  content: { attachmentId: string };
  name: string;
}[];

type FileUploadMetadata = {
  surveyId: string | undefined;
  name: string | undefined | null;
};

export const unexpectedFileUploadValue = (metadata: FileUploadMetadata) =>
  `CreateSurveyResponsePage: Unexpected file upload value in survey response when deleting individual file. Skipping deletion logic. Survey id: ${metadata?.surveyId}. question name inside survey.json: ${metadata?.name}.`;

const useValidateFileSchema = () => {
  const fileUploadValueSchema = yup.array(
    yup.object({
      content: yup.object({ attachmentId: yup.string().required() }).required(),
      name: yup.string().required(),
    })
  );

  const validateFileSchema = async (
    value: unknown,
    metadata: FileUploadMetadata
  ): Promise<boolean> => {
    try {
      await fileUploadValueSchema.validate(value);
      return true;
    } catch (error) {
      const errorMessage = unexpectedFileUploadValue(metadata);
      console.error(errorMessage);
      captureException(error, { extra: { errorMessage } });

      return false;
    }
  };

  return validateFileSchema;
};

export default useValidateFileSchema;
