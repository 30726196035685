import { Box } from '@mantine/core';
import { Transaction } from '../../../shared/types';
import { Beta } from '@liveeo/component-library';
import { CellProps } from '../../../shared/components/NewTable/useColumns';

export const Products = ({ row }: CellProps<Transaction>) => {
  return (
    <Box>
      {row.components?.map((component) => (
        <Beta.Text ta="left" key={component.id}>
          {`${component.hsn}: ${component.commodity} (${component.quantity} ${component.unit})`}
        </Beta.Text>
      ))}
    </Box>
  );
};
