import { round } from 'lodash';

export const convertFloatToPercentage = (num: number) => {
  const percentage = num * 100;

  if (percentage < 1) {
    return '<1%';
  }

  return `${round(percentage, 2)}%`;
};
