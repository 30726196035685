import { Component, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Beta,
  Button,
  Center,
  GenericErrorMessage,
  Loader,
  Modal,
  Radio,
  Select,
  Stack,
  TextInput,
} from '@liveeo/component-library';
import { useViewportSize } from '@mantine/hooks';
import {
  Controller,
  Control,
  SubmitHandler,
  useForm,
  FormProvider,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  CountryList,
  countryOptions,
  EU_COUNTRY_LIST,
} from '../../../../assets/countryList';
import { useUser } from '../../../hooks';
import { HsCodeSelect } from './HsCodeSelect';
import { useTransactions } from '../../../hooks/useTransactions';
import {
  ActivityType,
  TransactionFormData,
  TransactionStatus,
} from '../../../shared/types/Transaction';
import { TransactionFormButton } from './TransactionFormButton';
import { Components } from './Components';
import { useQueryParam } from 'use-query-params';
import classes from '../../../shared/components/Inputs/Inputs.module.css';

type TransactionFormProps = {
  cancel: () => void;
};

const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]+$/;
const ALPHANUMERIC_COMMA_SPACES_REGEX = /^[,a-zA-Z0-9\s]+$/;

const CountrySelect = ({
  control,
  countryList,
  error,
  label,
}: {
  control: Control<TransactionFormData>;
  countryList: CountryList[];
  error: string | undefined;
  label: string;
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="countryOfActivity"
      render={({ field }) => (
        <Select
          {...field}
          clearable
          data={countryList.map(({ label, value }) => ({
            value: value,
            label: t<string>(label),
          }))}
          label={label}
          withAsterisk
          searchable
          className={classes.flexGrow}
          classNames={{
            input: classes.darkBackground,
            dropdown: classes.darkBackground,
          }}
          error={error}
        />
      )}
    />
  );
};

const styles = {
  radio: {
    backgroundColor: 'transparent',
  },
};

export const TransactionForm = ({ cancel }: TransactionFormProps) => {
  const { transactionId } = useParams();
  const [search] = useQueryParam<string>('search');
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(!!search);
  const { width } = useViewportSize();
  const { data } = useUser();
  const { hsCodes, useTransactionById } = useTransactions();
  const { data: transaction } = useTransactionById(transactionId);
  const [loading, setLoading] = useState(transactionId !== 'new');

  const businessAddressCountryCode = data?.business?.addressCountryCode;

  const labels = {
    activityType: t<string>('transactions.form.activity'),
    countryOfActivity: t<string>('transactions.form.countryOfActivity'),
    internalReference: t<string>('transactions.form.internalReference'),
    description: t<string>('transactions.form.description'),
    quantity: t<string>('common.units.kgm'),
    supplier: t<string>('common.roles.supplier'),
  };

  const transactionSchema = yup.object().shape({
    activityType: yup
      .string()
      .oneOf(Object.values(ActivityType))
      .required(
        t<string>('validations.required', { field: labels.activityType })
      ),
    customId: yup
      .string()
      .matches(ALPHANUMERIC_REGEX, t<string>('validations.alphanumeric'))
      .max(
        50,
        t<string>('validations.maxLength', {
          field: labels.internalReference,
          length: 50,
        })
      )
      .required(
        t<string>('validations.required', {
          field: labels.internalReference,
        })
      ),
    countryOfActivity: yup
      .string()
      .oneOf(EU_COUNTRY_LIST.map((country) => country.value))
      .required(
        t<string>('validations.required', { field: labels.countryOfActivity })
      ),
    components: yup.array().of(
      yup.object().shape({
        description: yup
          .string()
          .required(
            t<string>('validations.required', { field: labels.description })
          )
          .max(
            150,
            t<string>('validations.maxLength', {
              field: labels.description,
              length: 150,
            })
          ),
        quantity: yup
          .number()
          .required(
            t<string>('validations.required', { field: labels.quantity })
          )
          .min(1, t<string>('validations.positive', { field: labels.quantity }))
          .test(
            'max3Decimals',
            t<string>('validations.maxDecimals', {
              field: labels.quantity,
              count: 3,
            }),
            (value) => {
              if (value === undefined) return true;
              const valueString = value.toString();
              const decimalIndex = valueString.indexOf('.');
              if (decimalIndex === -1) return true;
              return valueString.slice(decimalIndex + 1).length <= 3;
            }
          ),
        supplierBusiness: yup.object().shape({
          id: yup
            .string()
            .required(
              t<string>('validations.required', { field: labels.supplier })
            ),
        }),
        namePairs: yup.array().when('commodity', {
          is: (value: string) => value === 'timber',
          then: yup.array().of(
            yup.object().shape({
              scientificName: yup
                .string()
                .matches(
                  ALPHANUMERIC_COMMA_SPACES_REGEX,
                  t<string>('validations.alphanumericCommaSpaces')
                ),
              commonName: yup
                .string()
                .matches(
                  ALPHANUMERIC_COMMA_SPACES_REGEX,
                  t<string>('validations.alphanumericCommaSpaces')
                ),
            })
          ),
        }),
      })
    ),
  });

  const form = useForm<TransactionFormData>({
    resolver: yupResolver(transactionSchema),
  });

  const {
    control,
    formState: { errors },

    getValues,
    reset,
    register,
    setValue,
    watch,
  } = form;

  const transactionUrl = '/map/transactions?pan=1';

  const handleDeleteComponent = (hsn: string) => {
    const currentComponents = getValues('components');
    const updatedComponents = currentComponents.filter((component) => {
      return component.hsn !== hsn;
    });
    setValue('components', updatedComponents);
    const currentSelectedHsCodes = getValues('selectedHsCodes');
    const updatedSelectedHsCodes = currentSelectedHsCodes.filter(
      (code: string) => code !== hsn
    );
    setValue('selectedHsCodes', updatedSelectedHsCodes);
  };
  const selectedActivity = watch('activityType');

  useEffect(() => {
    if (transaction) {
      const formData = {
        activityType: transaction?.activityType,
        components: transaction?.components,
        customId: transaction?.customId,
        countryOfActivity: businessAddressCountryCode || '',
        selectedHsCodes: transaction?.components?.map((c) => c.hsn) || [],
        status: transaction?.status || TransactionStatus.DRAFT,
      };

      reset(formData);
      setLoading(false);
    }
  }, [transaction, reset, businessAddressCountryCode]);

  if (loading) {
    return (
      <Center h="100vh">
        <Loader />
      </Center>
    );
  }

  const doesTransactionExist = !!transaction;

  return (
    <GenericErrorMessage>
      <FormProvider {...form}>
        <Beta.Text variant="h1">{t('transactions.form.title')}</Beta.Text>
        <form>
          <Beta.Text>{t('transactions.form.information')}</Beta.Text>
          <TextInput
            mt="md"
            {...register('customId')}
            label={labels.internalReference}
            className={classes.flexGrow}
            classNames={{
              input: classes.darkBackground,
            }}
            error={errors.customId?.message}
          />
          <Controller
            name="activityType"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <Radio.Group
                mt="lg"
                label={t('transactions.form.activity')}
                {...field}
                aria-disabled={doesTransactionExist}
              >
                <Stack mt="xs">
                  <Radio
                    value="IMPORT"
                    label={t('transactions.activity.IMPORT')}
                    styles={styles}
                    error={errors.activityType?.message}
                    disabled={doesTransactionExist}
                  />
                  {selectedActivity === 'IMPORT' && (
                    <CountrySelect
                      control={control}
                      countryList={countryOptions(EU_COUNTRY_LIST)}
                      error={errors.countryOfActivity?.message}
                      label={t('transactions.form.countryOfEntry')}
                    />
                  )}
                  <Radio
                    value="EXPORT"
                    label={t('transactions.activity.EXPORT')}
                    styles={styles}
                    error={errors.activityType?.message}
                    disabled={doesTransactionExist}
                  />
                  {selectedActivity === 'EXPORT' && (
                    <CountrySelect
                      control={control}
                      countryList={countryOptions(EU_COUNTRY_LIST)}
                      error={errors.countryOfActivity?.message}
                      label={t('transactions.form.countryOfExit')}
                    />
                  )}
                  <Radio
                    value="DOMESTIC"
                    label={t('transactions.activity.DOMESTIC')}
                    styles={styles}
                    error={errors.activityType?.message}
                    disabled={doesTransactionExist}
                  />
                  {selectedActivity === 'DOMESTIC' && (
                    <CountrySelect
                      control={control}
                      countryList={countryOptions(EU_COUNTRY_LIST)}
                      error={errors.countryOfActivity?.message}
                      label={labels.countryOfActivity}
                    />
                  )}
                </Stack>
              </Radio.Group>
            )}
          />
          <Components
            control={control}
            setValue={setValue}
            errors={errors.components}
            handleDelete={handleDeleteComponent}
          />
          <Stack mt="lg">
            <Button
              variant="outline"
              size="md"
              onClick={() => setIsModalOpen(true)}
              w="50%"
            >
              <Beta.Icon icon="plus" />
              {t('transactions.form.addProduct')}
            </Button>
          </Stack>
          <TransactionFormButton
            setValue={setValue}
            transactionUrl={transactionUrl}
          />
        </form>
        {isModalOpen && (
          <Modal
            centered
            onClose={() => setIsModalOpen(false)}
            opened={isModalOpen}
            radius="md"
            size={width - 50}
            title={t('transactions.form.hsCodes')}
          >
            <HsCodeSelect
              control={control}
              hsCodes={hsCodes || []}
              setIsModalOpen={setIsModalOpen}
              setValue={setValue}
              name="selectedHsCodes"
              selectedHsCodes={watch('selectedHsCodes') || []}
            />
          </Modal>
        )}
      </FormProvider>
    </GenericErrorMessage>
  );
};
