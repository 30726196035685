import { useTranslation } from 'react-i18next';
import { showNotification } from '@liveeo/component-library';
import { capitalizeFirstLetter } from '@liveeo/helpers';
import { isApiError } from '../helper/api';

export const useNotification = () => {
  const { t } = useTranslation();

  const showSuccess = (message: string) =>
    showNotification({
      title: t('success.title'),
      message,
      color: 'green',
    });

  const showError = (message: string) =>
    showNotification({
      title: t('error.title'),
      message,
      color: 'red',
    });

  const apiError = () => {
    return showNotification({
      title: t<string>('error.title'),
      message: t<string>('error.data'),
      color: 'red',
    });
  };

  const showErrorWithResponse = (error: unknown) => {
    let message = '';
    if (error instanceof Error || isApiError(error)) {
      message = error.message;
    } else {
      message = t<string>('error.data');
    }
    showError(message);
  };

  const successfullyCreated = (entity: string) => {
    return showNotification({
      title: t<string>('success.title'),
      message: t<string>('success.create', {
        entity: capitalizeFirstLetter(entity),
      }),
      color: 'green',
    });
  };

  const successfullyUpdated = (entity: string) => {
    return showNotification({
      title: t<string>('success.title'),
      message: t<string>('success.update', {
        entity: capitalizeFirstLetter(entity),
      }),
      color: 'green',
    });
  };

  const successfullyDeleted = (entity: string) => {
    return showNotification({
      title: t<string>('success.title'),
      message: t<string>('success.delete', {
        entity: capitalizeFirstLetter(entity),
      }),
      color: 'green',
    });
  };

  const successfullyInvited = (entity: string) => {
    return showNotification({
      title: t<string>('success.title'),
      message: t<string>('success.invited', {
        entity: capitalizeFirstLetter(entity),
      }),
      color: 'green',
    });
  };

  const successfullySubmitted = (entity: string) => {
    return showNotification({
      title: t<string>('success.title'),
      message: t<string>('success.submit', {
        entity: capitalizeFirstLetter(entity),
      }),
      color: 'green',
    });
  };

  return {
    apiError,
    successfullyCreated,
    successfullyDeleted,
    successfullyInvited,
    successfullySubmitted,
    successfullyUpdated,
    showSuccess,
    showError,
    showErrorWithResponse,
  };
};
