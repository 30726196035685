import { Icon, Menu } from '@liveeo/component-library';
import { UnstyledButton } from '@mantine/core';
import { Plot } from '../../shared/types';
import { useTracking, useUser } from '../../hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './PlotsActionsMenu.module.css';
import { SELECTED_PLOT_KEY } from '../../common/constants';
import { defer } from 'lodash';

type MenuProps = {
  item: Plot;
  selectedPlotId: string | undefined | null;
  disabled: boolean;
};

export const usePlotActions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { data: user } = useUser();

  const handleEditPlot = (event: React.MouseEvent, plot?: Plot) => {
    trackEvent('Plot-table', {
      step: 'edit-plot',
      action: 'fly-to',
    });

    searchParams.delete(SELECTED_PLOT_KEY);
    setSearchParams(searchParams);

    defer(() => {
      // wait for the search params to be deleted
      navigate(
        {
          pathname: `/map/plots/edit-plot/${plot?.id}`,
          search: searchParams.toString(),
        },
        {
          replace: true,
        }
      );
    });

    event.stopPropagation();
  };

  const handleDeletePlot = (event: React.MouseEvent, plot?: Plot) => {
    navigate({
      pathname: `/map/plots/delete-plot/${plot?.id}`,
      search: searchParams.toString(),
    });
    event.stopPropagation();
  };

  const showEdit = (plot?: Plot) => !plot?.mostRecentAnalysesByType?.PRECISION;

  const showOptions = (plot?: Plot) =>
    user?.businessId === plot?.suppliers[0].id;

  return { handleEditPlot, handleDeletePlot, showOptions, showEdit };
};

export const PlotsActionsMenu = ({
  item,
  selectedPlotId,
  disabled,
}: MenuProps) => {
  const { t } = useTranslation();

  const { handleEditPlot, handleDeletePlot, showEdit } = usePlotActions();

  const showEditPlotOption = showEdit(item);
  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <UnstyledButton
          w={10}
          ta="center"
          data-testid="row-options-btn"
          disabled={disabled}
          onClick={(e) => {
            if (item.id === selectedPlotId) {
              e.stopPropagation();
            }
          }}
        >
          <Icon icon="ellipsis-vertical" color="dark.0" />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown bg="dark.7">
        {showEditPlotOption && (
          <>
            <Menu.Item
              className={classes.item}
              leftSection={<Icon icon="edit" size="xs" />}
              onClick={(e) => handleEditPlot(e, item)}
            >
              {t<string>('common.edit-plot')}
            </Menu.Item>
            <Menu.Divider />
          </>
        )}
        <Menu.Item
          className={classes.item}
          leftSection={<Icon icon="trash" size="xs" />}
          onClick={(e) => handleDeletePlot(e, item)}
        >
          {t<string>('common.delete')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
