import { useEffect } from 'react';
import { TableVirtuosoHandle } from 'react-virtuoso';

const useScrollToSelection = <T extends { id: string }>(
  ref: React.RefObject<TableVirtuosoHandle>,
  data: T[],
  selectedId?: string
) => {
  const selectedIndex = data.findIndex((item) => item.id === selectedId);

  useEffect(() => {
    // scroll to the selected index if it exists
    if (selectedIndex !== -1) {
      setTimeout(() => {
        ref?.current?.scrollToIndex({
          index: selectedIndex,
          align: 'start',
          behavior: 'smooth',
        });
      }, 300);
    }
  }, [selectedIndex, data, ref, selectedId]);

  return { selectedIndex };
};

export default useScrollToSelection;
