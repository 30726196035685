import { useTranslation } from 'react-i18next';
import { Modal, Stack, Text, Title } from '@liveeo/component-library';
import { InviteUser } from './InviteUser';
import { StringParam, useQueryParam } from 'use-query-params';

export const InviteMembers = () => {
  const { t } = useTranslation();
  const [invite, setInvite] = useQueryParam('invite', StringParam);

  const handleClearInvite = () => {
    setInvite(null);
  };

  return (
    <Modal
      title={<Title order={4}>{t('invitations.modal.title')}</Title>}
      opened={!!invite}
      onClose={handleClearInvite}
      centered
    >
      <Stack>
        <Text>{t('invitations.modal.description')}</Text>
        <InviteUser />
      </Stack>
    </Modal>
  );
};
