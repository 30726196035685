import { Grid, Flex } from '@liveeo/component-library';
import UserProfile from './UserProfile';
import { useFlags } from '../../contexts/FlagsContext';
import classes from './Admin.module.css';
import { InvitationsTable } from './Invitations/InvitationsTable';
import { GenericErrorBoundary } from '../../shared/components/GenericErrorBoundary';
import {
  BooleanParam,
  NumberParam,
  QueryParamProvider,
  StringParam,
} from 'use-query-params';
import { SELECTED_PLOT_KEY } from '../../common/constants';

export const AdminPage = () => {
  return (
    <QueryParamProvider
      options={{
        params: {
          pg: NumberParam,
          [SELECTED_PLOT_KEY]: StringParam,
          ex: NumberParam,
          sort: StringParam,
          desc: BooleanParam,
        },
        removeDefaultsFromUrl: false,
        updateType: 'replaceIn',
      }}
    >
      <GenericErrorBoundary title="Unable to display admin page">
        <Flex className={classes.admin}>
          <UserProfile />
          <InvitationsTable />
        </Flex>
      </GenericErrorBoundary>
    </QueryParamProvider>
  );
};
