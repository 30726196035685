import { LngLatLike, useMap } from 'react-map-gl';
import { useMapViewState } from './useMapViewState';
import { calculatePadding, defaultMapViewState, getBbox } from '../helper';
import { isEqual } from 'lodash';
import { Plot } from '../shared/types';

const useShowPlot = () => {
  const { deforestationMap: map } = useMap();
  const { viewState } = useMapViewState();

  const showPlot = (plot: Plot) => {
    const bbox = getBbox(plot.geometry);
    if (!bbox.length) return;
    if (isEqual(defaultMapViewState, viewState)) {
      map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
        padding: calculatePadding(),
      });
    } else {
      map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
        duration: 0, // disable animation when not at defaultMapViewState
        padding: calculatePadding(),
      });
    }
  };

  return showPlot;
};

export default useShowPlot;
