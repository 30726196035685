export const FOOTER_HEIGHT = 58;
export const INFO_SECTION_HEIGHT = 250;
export const INFO_SECTION_MARGIN = 40;
export const HEADER_HEIGHT = 20;

export const DRAW_POLYGON = 'draw_polygon';
export const SIMPLE_SELECT = 'simple_select';

export const PLOT_LIMIT_SIZE_HA = 10000;

export const LAYER_COLORS = {
  precision: '#D4111B',
  openSource: '#B7D411',
};

export const PRIVACY_POLICY_URL = 'https://www.live-eo.com/privacy-policy';

export type SelectedKey = 'plot-id' | 'supplier-id';

export const SELECTED_PLOT_KEY = 'plot-id';
export const SELECTED_SUPPLIER_KEY = 'supplier-id';
