import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import { Business, BusinessConnection, Supplier } from '../shared/types';
import { useGetSupplierRiskAssessments } from './useSupplierRiskAssessments';

type UseSuppliersResult = {
  data: Supplier[] | undefined;
  isLoading: boolean;
  isFetching: boolean;
  findSupplierById: (supplierId: string) => Business | undefined;
};

export const useSuppliers = (): UseSuppliersResult => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();
  const queryClient = useQueryClient();
  const { data: riskAssessments } = useGetSupplierRiskAssessments();

  const { data, isLoading, isFetching } = useQuery<
    BusinessConnection[],
    Error,
    Supplier[]
  >({
    queryKey: ['suppliers'],
    queryFn: () =>
      fetch('business-connections/suppliers') as Promise<BusinessConnection[]>,
    onError: apiError,
    refetchOnWindowFocus: false,
    select: (data) => {
      return data.map((supplier) => {
        const {
          name,
          addressLine1,
          addressPostcode,
          addressCountry,
          contactPerson,
        } = supplier.targetBusiness;

        const status = riskAssessments?.find(
          (assessment) => assessment.supplierId === supplier.targetBusiness.id
        )?.status;

        const supplierName = [
          contactPerson?.firstName,
          contactPerson?.lastName,
        ].join(' ');

        const address = [addressLine1, addressPostcode, addressCountry].join(
          ', '
        );

        return {
          ...supplier,
          supplierName,
          supplierEmail: contactPerson?.email,
          name,
          address,
          status,
        };
      });
    },
  });

  const findSupplierById = (supplierBusinessId: string) =>
    queryClient
      .getQueryData<Supplier[]>(['suppliers'])
      ?.find(({ targetBusiness }) => targetBusiness.id === supplierBusinessId)
      ?.targetBusiness;

  return {
    data,
    isLoading,
    isFetching,
    findSupplierById,
  };
};
