import { Transaction } from '../../../shared/types';
import { Text } from '@liveeo/component-library';
import { CellProps } from '../../../shared/components/NewTable/useColumns';
import { formatDate } from '../../../helper/date-utils';

export const SubmissionDate = ({ row }: CellProps<Transaction>) => {
  const date = formatDate(row.updatedAt);

  return <Text ta="left">{row.status === 'DRAFT' ? '----' : date}</Text>;
};
