import { SidePanelContainer } from '../../../pages/Map/SidePanelContainer';
import { Button, Beta, Box } from '@liveeo/component-library';
import classes from './NewTable.module.css';

export type HeaderProps = {
  title?: string;
  onAdd?: () => void;
  action?: React.ReactNode;
};

const Header = ({ title, onAdd, action }: HeaderProps) => {
  return (
    <Box className={classes.title}>
      <SidePanelContainer.Title title={title ?? ''} />
      {onAdd && (
        <Button
          variant="transparent"
          size="sm"
          onClick={onAdd}
          data-testid="add-button"
        >
          <Beta.Icon icon="plus" />
        </Button>
      )}
      {action}
    </Box>
  );
};

export default Header;
