import { useToggle } from '@mantine/hooks';
import { useSupplierPlots, useSuppliers, useTracking } from '../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import NewTable from '../../../shared/components/NewTable/NewTable';
import { Supplier } from '../../../shared/types';
import PlotsSidePanel from '../PlotsTablePanel/PlotsSidePanel';
import SupplierSidePanel from './SupplierSidePanel';
import { InviteSuppliers } from '../../Suppliers/InviteSuppliers';
import { SELECTED_PLOT_KEY } from '../../../common/constants';
import { CellValue } from '../../../shared/components/NewTable/useColumns';

const NewSuppliersTablePanel = () => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const [isModalOpen, toggleModal] = useToggle();
  const { data: suppliers, isLoading } = useSuppliers();
  const [searchParams] = useSearchParams();
  const supplierId = searchParams.get('supplier-id');
  const plotId = searchParams.get(SELECTED_PLOT_KEY);
  const selectedSupplier = suppliers?.find(
    (supplier) => supplier.id === supplierId
  );
  const { data: selectedSupplierPlots } = useSupplierPlots(
    selectedSupplier?.targetBusiness?.id ?? ''
  );

  useEffect(() => {
    trackEvent('Supplier', {
      step: 'supplier-page',
      action: 'open',
    });
  }, [trackEvent]);

  const columns = [
    { value: 'name', label: t('supplier.labels.company'), sort: true },
    { value: 'supplierName', label: t('supplier.labels.name'), sort: true },
    // TODO: add commodity and status columns when we have the data
    // { value: 'commodity', label: t('supplier.labels.commodities'), sort: true },
    { value: 'supplierEmail', label: t('supplier.labels.email'), sort: true },
    { value: 'address', label: t('supplier.labels.address'), sort: true },
    { value: 'plotsCount', label: t('supplier.labels.plots'), sort: true },
    {
      value: 'status',
      label: t('supplier.labels.status'),
      sort: true,
      renderText: (status: CellValue) =>
        t<string>(`suppliers.riskAssessment.status.${status ?? 'NEW'}`),
    },
  ];

  const cardRows = [
    ['name', 'plotsCount'],
    ['commodity', 'status'],
    ['supplierName'],
  ];

  return (
    <>
      <NewTable<Supplier>
        header={{
          title: t<string>('suppliers.title'),
          onAdd: () => toggleModal(),
        }}
        data={suppliers}
        columns={columns}
        loading={isLoading}
        empty={{
          entity: t('suppliers.title').toLowerCase(),
          onClick: () => toggleModal(),
          icon: 'user-plus',
        }}
        cardRows={cardRows}
        virtualized
        search={{
          keys: ['name', 'supplierName', 'email'],
          placeholder: 'Search suppliers by company, name or email',
        }}
        selectKey="supplier-id"
        aside={
          plotId ? (
            <PlotsSidePanel plots={selectedSupplierPlots} />
          ) : (
            <SupplierSidePanel data={suppliers} />
          )
        }
      />

      <InviteSuppliers isOpen={isModalOpen} toggleOpen={toggleModal} />
    </>
  );
};

export default NewSuppliersTablePanel;
