import { Box, Card, Text } from '@mantine/core';
import { get } from 'lodash';
import { Column, useCardColumns } from './useColumns';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import {
  createElement,
  forwardRef,
  HTMLAttributes,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import classes from './NewTable.module.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SELECTED_PLOT_KEY, SelectedKey } from '../../../common/constants';
import { useMap } from 'react-map-gl';
import { useHoveredPlotCallback } from '../../../hooks';
import clsx from 'clsx';
import useScrollToSelection from './useScrollToSelection';

type CardListProps<T> = {
  data: T[];
  cardRows: string[][];
  onSelect?: (card: T) => void;
  columns: Pick<Column<T>, 'value' | 'cell'>[];
  height?: string | number | undefined;
  listProps?: HTMLAttributes<HTMLDivElement>;
  selectKey?: SelectedKey;
  previousKey?: SelectedKey;
  renderCardBorderRight?: (card: T) => string | undefined;
};

const CardList = <T extends { id: string }>({
  data,
  cardRows,
  onSelect,
  columns,
  height = 'calc(100dvh - 120px)',
  listProps,
  selectKey,
  previousKey,
  renderCardBorderRight,
}: CardListProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { deforestationMap: map } = useMap();
  const { hoveredFeatureId, setHoveredFeatureIdRow, clearHoverdFeatureIdRow } =
    useHoveredPlotCallback(map);
  const location = useLocation();
  const plotsPage = location.pathname.includes('/plots');
  const cardListRef = useRef<VirtuosoHandle>(null);
  const selectedId = searchParams.get(selectKey || '') ?? undefined;

  useScrollToSelection(cardListRef, data, selectedId);

  const cardColumns = useCardColumns(columns);

  const handleSelect = (record: T) => {
    if (typeof onSelect === 'function') {
      onSelect(record);
    }

    if (selectKey) {
      const search = {
        ...Object.fromEntries(searchParams.entries()),
        [selectKey]: record.id,
        plots: '1',
      };
      const newSearchParams = new URLSearchParams(search).toString();

      if (selectKey === SELECTED_PLOT_KEY && plotsPage) {
        navigate({
          pathname: '/map/plots',
          search: newSearchParams,
        });
        return;
      }

      setSearchParams(newSearchParams, {
        state: { previousKey },
      });
    }
  };

  const MemoizedList = useMemo(() => {
    return forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
      ({ children, ...props }, ref) => (
        <Box className={classes.cardList} ref={ref} {...listProps} {...props}>
          {children}
        </Box>
      )
    );
  }, [listProps]);

  return (
    <Virtuoso
      ref={cardListRef}
      data={data}
      style={{ height }}
      components={{
        List: MemoizedList,
      }}
      itemContent={(index) => {
        const card = data[index] as T;
        return (
          <Card
            key={card.id}
            onMouseOver={() => setHoveredFeatureIdRow(index)}
            onMouseOut={() => clearHoverdFeatureIdRow(index)}
            withBorder
            className={clsx(
              classes.card,
              hoveredFeatureId === index && classes.hovered
            )}
            onClick={() => handleSelect(card)}
            bg={selectedId === card.id ? '#6B666F' : undefined}
            style={{ borderRight: renderCardBorderRight?.(card) }}
          >
            {cardRows.map((row, rowIndex) => {
              return (
                <Box
                  className={classes.cardRow}
                  key={`row-${card.id}-${rowIndex}`}
                >
                  {row.map((attribute, attributeIndex: number) => {
                    const cardColumn = cardColumns.find(
                      ({ value }) => value === attribute
                    );

                    const value = get(card, attribute);

                    if (cardColumn?.cardCell) {
                      return createElement(cardColumn.cardCell, {
                        row: card,
                        getValue: () => value,
                        key: `cell-${card.id}-${rowIndex}-${attributeIndex}`,
                      });
                    }

                    return (
                      <Text
                        key={`text-${card.id}-${rowIndex}-${attributeIndex}`}
                        component="span"
                        size={`${rowIndex === 0 ? 'lg' : 'md'}`}
                      >
                        {cardColumn?.renderText
                          ? cardColumn.renderText(value)
                          : value}
                      </Text>
                    );
                  })}
                </Box>
              );
            })}
            <Box className={classes.cardStatus} />
          </Card>
        );
      }}
    />
  );
};

export default CardList;
