import { useEffect } from 'react';
import { Controller, Control } from 'react-hook-form';
import {
  Badge,
  Beta,
  Button,
  Card,
  Flex,
  NumberInput,
  Select,
  Stack,
  TextInput,
} from '@liveeo/component-library';
import {
  HsCode,
  Component,
  TransactionFormData,
} from '../../../shared/types/Transaction';
import { useTranslation } from 'react-i18next';
import { useSuppliers } from '../../../hooks/useSuppliers';
import { UseFormSetValue } from 'react-hook-form';
import { useTransactions } from '../../../hooks';
import { useParams } from 'react-router-dom';
import classes from '../../../shared/components/Inputs/Inputs.module.css';

const findParentRecursive = (
  codes: HsCode[],
  code: string
): {
  parentHsCode: string | null;
  hsCode: string;
  description: string;
} | null => {
  const currentCode = codes.find((c) => c.hsCode === code);
  if (!currentCode) return null;
  if (!currentCode.parentHsCode) return currentCode;
  return findParentRecursive(codes, currentCode.parentHsCode);
};

export const Components = ({
  control,
  setValue,
  errors,
  handleDelete,
}: {
  control: Control<TransactionFormData>;
  setValue: UseFormSetValue<TransactionFormData>;
  errors: any;
  handleDelete: (hsn: string) => void;
}) => {
  const { t } = useTranslation();
  const { data: suppliers } = useSuppliers();
  const { hsCodes = [] } = useTransactions();

  return (
    <Controller
      name="components"
      rules={{ required: true }}
      control={control}
      render={({ field }) => (
        <Stack mt="xl">
          {[...(field.value || [])].map(
            (component: Component, index: number) => {
              const parentHsCode = findParentRecursive(
                hsCodes || [],
                component.hsn
              );
              return (
                <Card
                  key={index}
                  padding="lg"
                  withBorder
                  styles={{
                    root: { backgroundColor: 'var(--mantine-color-dark-9)' },
                  }}
                >
                  <Flex
                    style={{ flex: '1 1 auto', overflow: 'visible' }}
                    mb="md"
                  >
                    {!!parentHsCode && (
                      <>
                        <Badge
                          color="dark.8"
                          size="lg"
                          radius="xs"
                          mr="sm"
                          style={{ width: '50px', flex: '0 0 auto' }}
                        >
                          {parentHsCode.hsCode}
                        </Badge>
                        <Beta.Text>{parentHsCode.description}</Beta.Text>
                      </>
                    )}
                  </Flex>
                  <Flex
                    style={{ flex: '1 1 auto', overflow: 'visible' }}
                    ml={50}
                  >
                    <Badge
                      color="dark.8"
                      size="lg"
                      radius="xs"
                      mr="sm"
                      mb="lg"
                      style={{
                        width: '70px',
                        flex: '0 0 auto',
                        padding: '0 10px',
                      }}
                    >
                      {component.hsn}
                    </Badge>
                    <Beta.Text>
                      {
                        hsCodes?.find((hs) => hs.hsCode === component.hsn)
                          ?.description
                      }
                    </Beta.Text>
                  </Flex>
                  <Flex>
                    <TextInput
                      label={t('transactions.form.productDescription')}
                      placeholder={
                        t('transactions.form.productDescriptionPlaceholder') ??
                        'Descriptiion'
                      }
                      value={component?.description || ''}
                      onChange={(event) => {
                        if (field.value) {
                          const newComponents = [...field.value];
                          const foundComponent = newComponents.find(
                            (nc: Component) => nc.hsn === component.hsn
                          );
                          if (foundComponent) {
                            foundComponent.description =
                              event.currentTarget.value;
                            field.onChange(newComponents);
                          }
                        }
                      }}
                      style={{ flex: 1, marginRight: '1rem' }}
                      classNames={{
                        input: classes.darkBackground,
                      }}
                      error={errors?.[index]?.description?.message}
                    />
                    <NumberInput
                      label={t('common.units.kgm')}
                      value={component.quantity || undefined}
                      suffix={t('common.units.kg') || ''}
                      onChange={(value) => {
                        if (field.value) {
                          const newComponents = [...field.value];
                          const foundComponent = newComponents.find(
                            (nc: Component) => nc.hsn === component.hsn
                          );
                          if (foundComponent) {
                            foundComponent.quantity = Number(value) || 0;
                            field.onChange(newComponents);
                          }
                        }
                      }}
                      classNames={{
                        input: classes.darkBackground,
                      }}
                      error={errors?.[index]?.quantity?.message}
                      min={0}
                    />
                  </Flex>
                  <Select
                    label={t('common.roles.supplier')}
                    placeholder={
                      t('transactions.form.supplierPlaceholder') || ''
                    }
                    value={component?.supplierBusiness?.id || ''}
                    onChange={(value) => {
                      if (field.value) {
                        const newComponents = [...field.value];
                        const foundComponent = newComponents.find(
                          (nc: Component) => nc.hsn === component.hsn
                        );
                        if (foundComponent) {
                          foundComponent.supplierBusiness.id = value || '';
                          field.onChange(newComponents);
                        }
                      }
                    }}
                    data={
                      suppliers
                        ?.map((supplier) => ({
                          label: supplier.targetBusiness.name,
                          value: supplier.targetBusiness.id,
                        }))
                        ?.filter(
                          (item): item is { label: string; value: string } =>
                            item.label !== undefined && item.value !== undefined
                        ) || []
                    }
                    style={{ flex: 1, marginTop: '1rem' }}
                    classNames={{
                      input: classes.darkBackground,
                      dropdown: classes.darkBackground,
                    }}
                    error={errors?.[index]?.supplierBusiness?.id?.message}
                  />
                  {component.commodity === 'timber' && (
                    <Flex mt="1rem">
                      <TextInput
                        label={t('transactions.form.scientificName')}
                        placeholder={
                          t('transactions.form.scientificNamePlaceholder') || ''
                        }
                        value={component?.namePairs?.[0]?.scientificName || ''}
                        onChange={(event) => {
                          if (field.value) {
                            const newComponents = [...field.value];
                            const foundComponent = newComponents.find(
                              (nc: Component) => nc.hsn === component.hsn
                            );
                            if (
                              foundComponent &&
                              foundComponent?.namePairs?.[0]
                            ) {
                              foundComponent.namePairs[0].scientificName =
                                event.currentTarget.value;
                              field.onChange(newComponents);
                            }
                          }
                        }}
                        style={{ flex: 1, marginRight: '1rem' }}
                        classNames={{
                          input: classes.darkBackground,
                        }}
                        error={
                          errors?.[index]?.namePairs?.[0]?.scientificName
                            ?.message
                        }
                      />
                      <TextInput
                        label={t('transactions.form.commonName')}
                        placeholder={
                          t('transactions.form.commonNamePlaceholder') || ''
                        }
                        value={component?.namePairs?.[0]?.commonName || ''}
                        onChange={(event) => {
                          if (field.value) {
                            const newComponents = [...field.value];
                            const foundComponent = newComponents.find(
                              (nc: Component) => nc.hsn === component.hsn
                            );
                            if (
                              foundComponent &&
                              foundComponent?.namePairs?.[0]
                            ) {
                              foundComponent.namePairs[0].commonName =
                                event.currentTarget.value;
                              field.onChange(newComponents);
                            }
                          }
                        }}
                        style={{ flex: 1 }}
                        classNames={{
                          input: classes.darkBackground,
                        }}
                        error={
                          errors?.[index]?.namePairs?.[0]?.commonName?.message
                        }
                      />
                    </Flex>
                  )}
                  <Flex mt="lg" justify="flex-end">
                    <Button
                      onClick={() => handleDelete(component.hsn)}
                      variant="outline"
                    >
                      {t('common.delete')}
                    </Button>
                  </Flex>
                </Card>
              );
            }
          )}
        </Stack>
      )}
    />
  );
};
