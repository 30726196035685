import { useState } from 'react';
import {
  Beta,
  Button,
  Flex,
  Menu,
  Modal,
  Textarea,
} from '@liveeo/component-library';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  AssessmentStatus,
  SupplierRiskAssessments,
  useSupplierRiskAssessments,
} from '../../../hooks';
import { useSearchParams } from 'react-router-dom';
import classes from './RiskAssessmentStatus.module.css';

const supplierStatus = [
  'NEW',
  'UNDER_REVIEW',
  'READY_FOR_ASSESSMENT',
  'CMS_ASSESSMENT_REQUESTED',
  'CMS_ASSESSMENT_COMPLETED',
  'IN_RISK_MITIGATION',
  'EUDR_COMPLIANT',
  'EUDR_NON_COMPLIANT',
];

const riskAssesmentSchema = yup.object().shape({
  notes: yup.string(),
  status: yup.string(),
  supplierId: yup.string(),
  id: yup.string(),
});

export const RiskAssessmentStatus = ({
  supplierId,
}: {
  supplierId: string;
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, createAssessment, updateAssessment } =
    useSupplierRiskAssessments();
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  const modalOpened = !!searchParams.get('riskAssessmentModal');

  const assessment = data?.find(
    (supplierRiskStatus: SupplierRiskAssessments) =>
      supplierRiskStatus.supplierId === supplierId
  );

  const values = {
    notes: assessment?.notes ?? '',
    status: assessment?.status ?? 'NEW',
    supplierId,
    id: assessment?.id ?? '',
  };

  const form = useForm<SupplierRiskAssessments>({
    resolver: yupResolver(riskAssesmentSchema),
    values,
  });

  const toggleModal = (open: boolean) => {
    if (open) {
      searchParams.set('riskAssessmentModal', 'true');
      // reset notes if the status has changed
      if (assessment?.status !== form.getValues('status')) {
        form.setValue('notes', '');
      }
    } else {
      searchParams.delete('riskAssessmentModal');
    }
    setSearchParams(searchParams);
  };

  const handleCancel = () => {
    toggleModal(false);
    form.reset(values);
  };

  const onSubmit = (data: SupplierRiskAssessments) => {
    if (data.id) {
      updateAssessment.mutate(data, { onSuccess: () => toggleModal(false) });
    } else {
      createAssessment.mutate(data, { onSuccess: () => toggleModal(false) });
    }
  };

  return (
    <FormProvider {...form}>
      <Menu
        opened={menuOpened}
        onChange={setMenuOpened}
        offset={15}
        position="bottom-end"
      >
        <Menu.Target>
          <Flex justify="space-between" style={{ cursor: 'pointer' }}>
            {isLoading ? (
              <Beta.Text>{t('common.loading')}...</Beta.Text>
            ) : (
              <Beta.Text>
                {t(
                  `suppliers.riskAssessment.status.${form.getValues('status')}`
                ) ?? t('suppliers.riskAssessment.status.NEW')}
              </Beta.Text>
            )}
            <Beta.Icon icon="chevron-down" />
          </Flex>
        </Menu.Target>
        <Menu.Dropdown bg="dark.9">
          {supplierStatus.map((status) => (
            <Menu.Item
              key={status}
              className={classes.menuItem}
              onClick={() => {
                form.setValue('status', status as AssessmentStatus);
                toggleModal(true);
              }}
            >
              {t(`suppliers.riskAssessment.status.${status}`)}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
      <Modal
        centered
        opened={modalOpened}
        onClose={handleCancel}
        title={t('suppliers.riskAssessment.changeStatusWithValue', {
          status: t(
            `suppliers.riskAssessment.status.${form.getValues('status')}`
          ),
        })}
      >
        <Textarea
          label={t('suppliers.riskAssessment.notes')}
          placeholder={t<string>('suppliers.riskAssessment.clickToAddNote')}
          variant="outline"
          {...form.register('notes')}
          styles={{
            input: {
              border: '1px solid white',
            },
          }}
        />
        <Flex justify="flex-end" gap={10} mt="md">
          <Button
            radius="md"
            variant="outline"
            type="button"
            onClick={handleCancel}
          >
            {t('common.cancel')}
          </Button>
          <Button
            radius="md"
            variant="outline"
            type="submit"
            onClick={form.handleSubmit(onSubmit)}
          >
            {t('suppliers.riskAssessment.changeStatus')}
          </Button>
        </Flex>
      </Modal>
    </FormProvider>
  );
};
